import React from 'react'
import { withRouter } from 'react-router-dom'
import control, { alertDanger, alertSuccess, form, local, modal, ocultarModal, setLocal } from '../../../core/core'
import RoundSpinner from '../../../components/roundSpinner/roundSpinner'
import vehiculos_marca from '../../../components/storage/vehiculos/marcas'
import Table from '../../../components/table/table'
import headerSection from '../../../components/headerSection'

import { GrEdit } from "react-icons/gr";


class Marcas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            marcas: []
        }
    }


    async componentDidMount() {
        setLocal({ item: 'vehiculos_marcas', value: false })

        this.setState({ marcas: await vehiculos_marca() })

        this.setState({ loading: false })
    }

    async procesarNuevaMarca(e) {
        const nuevaMarca = await form({ api: 'vehiculos/marcasYmodelos', form: 'nuevaMarca' })
        if (nuevaMarca.respuesta) {
            await this.setState(prevState => ({
                ...prevState,
                marcas: [...prevState.marcas, nuevaMarca.respuesta]
            }))

            alertSuccess('Marca creada con éxito')

            this.setInLocal(this.state.marcas)


            ocultarModal()
        } else {
            alertDanger('Ha ocurrido un error al crear la marca')
        }
    }

    async procesarEditarMarca(e) {
        const editarMarca = await form({ api: 'vehiculos/marcasYmodelos', form: 'editarMarca' })
        if (editarMarca.respuesta) {
            let marcas = local('vehiculos_marcas')
            const index = marcas.findIndex(e => parseFloat(e.idMarca) === parseFloat(editarMarca.respuesta.idMarca))

            if (~index) marcas[index] = editarMarca.respuesta

            this.setState(prevState => ({
                ...prevState,
                marcas: marcas
            }))

            this.setInLocal(marcas)

            alertSuccess('Marca editada correctamente')

            ocultarModal()
        } else {
            alertDanger('Ha ocurrido un error al crear la marca')
        }
    }

    setInLocal(marcas){
        setLocal({item:'vehiculos_marcas',value:marcas})
    }


    async eliminarMarca(idMarca){
        const eliminar = await control({api:'vehiculos/marcasYmodelos',request:'eliminarMarca',data:idMarca})

        if(eliminar.respuesta){

            const marcas = this.state.marcas.filter(e => parseFloat(e.idMarca) !== parseFloat(idMarca))

            await this.setState(prevState => ({
                ...prevState,
                marcas: marcas
            }))


            this.setInLocal(this.state.marcas)

            ocultarModal()
        }else{
            alertDanger('No se pudo eliminar la marca')
        }
    }

    procesarFormulario(e, tipoFormulario) {
        e.preventDefault()

        if (tipoFormulario === 'nuevaMarca') {
            this.procesarNuevaMarca()
        } else {
            this.procesarEditarMarca()
        }
    }


    confirmarEliminarMarca(marca){


        return modal({
            header:'Eliminiar marca',
            contenido: <>
                <h2>¿ Quieres eliminar {marca.titulo} ?</h2>
                <button type="button" className="outlineDanger" onClick={() => this.eliminarMarca(marca.idMarca)}>Si eliminar</button>
                <button type="button" className="success" onClick={() => ocultarModal()}>No eliminar</button>
            </>
        })
    }
    formularioMarca(d = {
        idMarca: 0,
        titulo: ''
    }) {
        const tipoFormulario = d.idMarca === 0 ? 'nuevaMarca' : 'editarMarca'
        return (
            <>
                <form id={tipoFormulario} onSubmit={e => this.procesarFormulario(e, tipoFormulario)}>

                    <div className="inputSection wrap">
                        <label>Nombre de la marca</label>
                        <input type="text" id="titulo" placeholder="Ingresa el nombre de la marca" defaultValue={d.titulo} required />
                    </div>
                    <div className="inputSection">
                        <button type="submit" className="success">
                            Guardar
                        </button>
                        <button onClick={() => ocultarModal()} className="outlineDanger">
                            Cancelar
                        </button>
                        {
                            tipoFormulario === 'editarMarca' ? (
                                <>
                                    <input type="hidden" id="idMarca" value={d.idMarca} />
                                    <button onClick={() => this.confirmarEliminarMarca(d)}>
                                        Eliminar
                                    </button>
                                </>
                            ) : (
                                null
                            )
                        }
                    </div>
                </form>
            </>
        )
    }
    editarMarca(marca){
        return modal(
            {
                header: 'Editar marca',
                contenido: this.formularioMarca(marca)
            }
        )
    }
    nuevaMarca() {
        return modal(
            {
                header: 'Nueva marca',
                contenido: this.formularioMarca({ idMarca: 0 })
            }
        )
    }
    render() {

        return (
            <>
                {
                    headerSection('Marcas')
                }

                <div className="appElement">
                    <button type="button" className="outlineSuccess" onClick={() => this.nuevaMarca()}>
                        + Nueva marca
                    </button>
                    {
                        Table({
                            id: 'marcas',
                            thead: [{
                                'title': 'Titulo'
                            }, {
                                'title': ' '
                            }
                            ],
                            tbody: this.state.loading ? (
                                <RoundSpinner />
                            ) : (
                                this.state.marcas.length > 0 ? (
                                    this.state.marcas.map(e => {
                                        return (
                                            <tr>

                                                <td>
                                                    {e.titulo}
                                                </td>
                                                <td>
                                                    <button type="button" className="outlineSuccess" onClick={() => this.editarMarca(e)}>
                                                        <GrEdit/>
                                                    </button>

                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No se encontraron marcas</td>
                                    </tr>
                                )
                            )
                        })
                    }
                </div>

            </>
        )

    }
}
export default withRouter(Marcas)