import { Route } from 'react-router-dom'

import Trabajadores from '../views/trabajadores/trabajadores'
import Trabajador from '../views/trabajadores/trabajador'
import MiPerfil from '../views/mi-perfil/mi-perfil'
import Vehiculos from '../views/vehiculos/vehiculos'
import Itinerarios from '../views/itinerarios/itinerarios'
import Vehiculo from '../views/vehiculo/vehiculo'
import Marcas from '../views/vehiculos/marcas'
import Modelos from '../views/vehiculos/modelos'
import Idiomas from '../views/idiomas/idiomas'
import Contratos from '../views/contratos/contratos'
import Itinerario from '../views/itinerario/itinerario'
import Participantes from '../views/participantes/participantes'
import Cuestionario from '../../publicSide/views/cuestionario/cuestionario'
import LiveMap from '../views/liveMap/LiveMap'
import Quizz from '../../publicSide/views/quizz/quizz'
import './styleAdmin.css'
import { ge } from '../../core/core'
export default function RouterAdmin() {
    ge('#root').classList.add('rootAdmin')

    return (
        <>
            <Route exact path="/vehiculo/:idVehiculo" >
                <Vehiculo />
            </Route >
            <Route exact path="/trabajador/:idTrabajador" >
                <Trabajador />
            </Route >
            <Route exact path="/" >
                <Itinerarios />
            </Route >
            <Route exact path="/dashboard" >
                <Itinerarios />
            </Route >
            <Route exact path="/trabajadores" >
                <Trabajadores />
            </Route >

            <Route exact path={["/cuestionario/:dniParticipante","/cuestionario"]} >
                    <Cuestionario />
                </Route>


            <Route exact path="/participantes" >
                <Participantes />
            </Route >
         
            <Route exact path="/itinerario/:idItinerario" >
                <Itinerario />
            </Route >
         

            <Route exact path="/quizz/:idItinerario" >
                <Quizz />
            </Route >
            <Route exact path="/quizz" >
                <Quizz />
            </Route >

            <Route exact path="/itinerarios" >
                <Itinerarios />
            </Route >
            <Route exact path="/vehiculos" >
                <Vehiculos />
            </Route >
            <Route exact path="/marcas" >
                <Marcas />
            </Route >
            <Route exact path="/idiomas" >
                <Idiomas />
            </Route >
            <Route exact path="/contratos" >
                <Contratos />
            </Route >
            <Route exact path="/modelos" >
                <Modelos />
            </Route >

            <Route exact path="/live-map" >
                    <LiveMap />
            </Route>


            <Route exact path="/mi-perfil" >
                <MiPerfil />
            </Route >
        </>
    )
}