import React from "react";
import { GrEdit } from "react-icons/gr";
import readExcel from "../../../components/excelToJson";
import RoundSpinner from "../../../components/roundSpinner/roundSpinner";
import Table from "../../../components/table/table";
import control, { alertDanger, form, ge, modal, ocultarAlert } from "../../../core/core";
import formularioParticipante from "./formularioParticipante";
import FormularioImportarRegistrosParticipantes, { validarParticipante } from "./importarParticipantes";

export default class Participantes extends React.Component {

    state = {
        loading: true,
        participantes: [],
        itinerarios: [],
        idItinerario: 0,
        terminoBusqueda: ''
    }

    async componentDidMount() {
        const itinerarios = await control({ api: 'itinerarios/itinerarios', request: 'listarItinerarios' })

        if (itinerarios.respuesta) this.setState({ itinerarios: itinerarios.respuesta })

        this.setState({ loading: false })
    }


    async filtrarParticipantes() {

        const participantes = await form({ form: 'filtrarParticipantes', api: 'participantes/participantes' })

        if (!participantes.respuesta) return alertDanger('No se encontraron participantes')

        this.setState({ participantes: participantes.respuesta })


    }





    async importarRegistros() {


        let data = await readExcel(ge('#exelFile').files)
        data = data[0].data


        ge('#exelFile').value = ''



        let registros = [],
            // eslint-disable-next-line no-unused-vars
            linea = 1


        await Promise.all(
            data.reverse().map(async e => {
                linea++


                const row = {
                    comarca: e['COMARCA'],
                    nombre: e['NOMBRE'],
                    telefono: e['TELEFONO'],
                    familiar: e['FAMILIAR'],
                    apellido: e['1ER APELLIDO'],
                    segundoApellido: e['2DO APELLIDO'],
                    dni: e['DNI'],
                    municipio: e['MUNICIPIO'],
                    acompaniante_apellido: e['1ER APELLIDO ACOMPANANTE'],
                    acompaniante_segundoApellido: e['2DO APELLIDO ACOMPANIANTE'],
                    acompaniante_nombre: e['NOMBRE ACOMPANIANTE'],
                    acompaniante_dni: e['DNI ACOMPANIANTE'],
                    acompaniante_municipio: e['MUNICIPIO ACOMPANIANTE'],
                    acompaniante_telefono: e['TELEFONO ACOMPANIANTE'],
                    plazas: e['PLAZAS'],
                    estado: e['ESTADO'] === 'PENDIENTE' ? 1 :
                        e['ESTADO'] === 'FALTA DE PAGO' ? 2 :
                            e['ESTADO'] === 'CONFIRMADO' ? 3 :
                                e['ESTADO'] === 'CANCELADO' ? 4 : 1,
                    fechaNacimiento: e['FECHA NACIMIENTO'],
                    acompaniante_fechaNacimiento: e['FECHA NACIMIENTO ACOMPANIANTE'],
                    datosFamiliarDiscapacidad: e['DATOS FAMILIAR DISCAPACITADO'] ?? null,
                    admitida: e['ADMITIDA'],
                    motivoExclusion: e['MOTIVO DE EXCLUSION'] ?? null,
                    expediente: e['EXPEDIENTE'],
                    registro: e['REGISTRO']

                }


                registros.push(row)




            })
        )

        this.setState({ importarRegistros: registros })

        return (
            <>

                {modal({
                    header: 'Importar registros',
                    contenido: <FormularioImportarRegistrosParticipantes registros={registros} itinerarios={this.state.itinerarios} />
                })}
            </>

        )

    }




    render() {
        return (
            this.state.loading ? (
                <RoundSpinner />
            ) : (
                <>
                    <div className="appElement">
                        <form id="filtrarParticipantes" onSubmit={e => {
                            e.preventDefault()
                            this.filtrarParticipantes()
                        }}>
                            <div className="grid3">
                                <div className="inputSection wrap">
                                    <label htmlFor="itinerario">Selecciona el itinerario</label>
                                    <select id="idItinerario" onChange={e => {
                                        this.setState({ itinerario: e.target.value })
                                        this.filtrarParticipantes()

                                    }}>
                                        <option value="0">Todos los itinerarios</option>
                                        {
                                            this.state.itinerarios ? (
                                                this.state.itinerarios.map(e => {
                                                    return <option value={e.idItinerario}>{e.titulo}</option>
                                                })
                                            ) : null
                                        }
                                    </select>
                                </div>
                                <div className="inputSection wrap">
                                    <label htmlFor="terminoBusqueda">Buscar por palabras</label>
                                    <input type="text" value={this.state.terminoBusqueda} id="terminoBusqueda" placeholder="Buscar por palabras" onChange={e => {
                                        this.setState({ terminoBusqueda: e.target.value })
                                        this.filtrarParticipantes()

                                    }} />
                                </div>
                            </div>

                        </form>

                        <a href="/templates_excel/participantes.ods" download="Plantilla participantes Mundo Señor">
                            <button type="button" className="outlineSuccess">
                                Descargar plantilla
                            </button>
                        </a>
                        <input type="file" className="hidden" id="exelFile" onChange={() => this.importarRegistros()} accept="*" />
                        <button type="button" className="success" onClick={() => ge('#exelFile').click()}>
                            Importar excel
                        </button>
                        <button type="button" className="outlineSuccess" onClick={() => formularioParticipante()}>
                            + Nuevo participante
                        </button>

                    </div>


                    <div className="appElement">

                        {
                            Table({
                                id: 'participantes',
                                thead: [{
                                    'title': ''
                                }, {
                                    'title': 'Nombre'
                                },
                                {
                                    'title': 'Teléfono'
                                }
                                ],
                                tbody: this.state.participantes.length > 0 ? (
                                    this.state.participantes.map(e => {
                                        return (<tr>
                                            <td>
                                                <button type="button" className="outlineSuccess" onClick={() => formularioParticipante({ idParticipante: e.idParticipante, nombre: e.nombre })}>
                                                    <GrEdit />
                                                </button>
                                            </td>
                                            <td>
                                                {e.nombre}
                                            </td>
                                            <td>
                                                {e.telefono}
                                            </td>
                                        </tr>)
                                    })
                                ) : (
                                    <tr><td></td><td>No hay participantes que listar. Intenta modificar el filtro</td></tr>
                                )
                            })
                        }

                    </div>

                </>
            )
        )
    }
}