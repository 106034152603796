import React from 'react'
import { withRouter } from 'react-router-dom'
import control, { alertDanger, alertSuccess, form } from '../../../core/core'
import RoundSpinner from '../../../components/roundSpinner/roundSpinner'
import vehiculos_marca from '../../../components/storage/vehiculos/marcas'
import vehiculos_modelos from '../../../components/storage/vehiculos/modelos'




class Vehiculo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            vehiculo: {
                idVehiculo: 0,
                idMarca: 0,
                idModelo: 0
            },
            marcas: [],
            modelos: []
        }

        this.handleInputChange = this.handleInputChange.bind(this)

    }


    handleInputChange(event) {
        const target = event.target
        let value = target.value

        if (target.type === 'checkbox') value = target.checked


        this.setState(prevState => ({
            ...prevState,
            vehiculo: {
                ...prevState.vehiculo,
                [target.id]: value
            }
        }))
    }



    async componentDidMount() {
        let { idVehiculo } = this.props.match.params

        idVehiculo = parseFloat(idVehiculo)

        if (idVehiculo > 0) {
            const detallesVehiculo = await control({ api: 'vehiculos/vehiculos', request: 'detallesVehiculo', data: idVehiculo })
            if (detallesVehiculo.respuesta) {

                this.setState({ vehiculo: detallesVehiculo.respuesta })
            }
        }

        await this.setState({
            marcas: await vehiculos_marca(),
            modelos: await vehiculos_modelos()

        })

        this.setState({ loading: false })
    }



    async updateVehiculo(e) {
        e.preventDefault()
        let update = await form({
            form: 'updateVehiculo',
            api: 'vehiculos/vehiculos'
        })

        if (!update.respuesta || !update.respuesta.status) return alertDanger(`Ha ocurrido un error. ${update.respuesta.mensaje !== null ? update.respuesta.mensaje : ''}`)

        let mensaje
        if (update.respuesta.nuevoVehiculo) {

            this.setState({ idVehiculo: update.respuesta.nuevoVehiculo }
            )
            mensaje = 'creado'

        } else {

            mensaje = 'actualizado'

        }

        alertSuccess(`Vehículo ${mensaje} con éxito`)
    }
    render() {


        let modelos = this.state.modelos



        if (this.state.vehiculo.idMarca && parseFloat(this.state.vehiculo.idMarca) > 0) {
            modelos = this.state.modelos.filter(e => parseFloat(e.idMarca) === parseFloat(this.state.vehiculo.idMarca))
        }
        return (
            this.state.loading ? (
                <RoundSpinner />
            ) : (
                <div className="grid2">
                    <div className="appElement">
                        <div className="appElementHeader">
                            {
                                this.state.vehiculo.idVehiculo > 0 ? (
                                    `Modificar ${this.state.vehiculo.nombre}`

                                ) : (
                                    'Registrar nuevo vehículo'

                                )
                            }
                        </div>
                        <div className="appElementContent">
                            <form id="updateVehiculo" onSubmit={e => this.updateVehiculo(e)}>
                                <input type="hidden" id="idVehiculo" value={this.state.vehiculo.idVehiculo} />
                                <div className="inputSection wrap">
                                    <label>Nombre</label>
                                    <input placeholder="Nombre identificativo el vehículo" type="text" value={this.state.vehiculo.nombre} id="nombre" onChange={this.handleInputChange} required />
                                </div>
                                <div className="inputSection wrap">
                                    <label>Matrícula</label>
                                    <input placeholder="Matrícula del vehículo" type="text" value={this.state.vehiculo.matricula} id="matricula" onChange={this.handleInputChange} required />
                                </div>
                                <div className="grid2">

                                    <div className="inputSection wrap">
                                        <label>Marca</label>
                                        <select placeholder="Selecciona la marca" value={this.state.vehiculo.idMarca} id="idMarca" onChange={this.handleInputChange} required >
                                            <option disabled>Selecciona una marca</option>
                                            {this.state.marcas.map(e => {
                                                return <option value={e.idMarca}>{e.titulo}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="inputSection wrap">
                                        <label>Modelo</label>
                                        <select placeholder="Selecciona la modelo" value={this.state.vehiculo.modelo} id="idModelo" onChange={this.handleInputChange} required >
                                            <option disabled>Selecciona un modelo</option>
                                            {modelos.map(e => {
                                                return <option value={e.idModelo}>{e.titulo}</option>
                                            })}
                                        </select>
                                    </div>

                                    <div className="inputSection wrap">
                                        <label>Capacidad</label>
                                        <input placeholder="Capacidad" type="number" min="1" value={this.state.vehiculo.capacidad} id="capacidad" onChange={this.handleInputChange} required />
                                    </div>

                                    <div className="inputSection wrap">
                                        <label>Rastreador</label>
                                        <input placeholder="Rastreador" type="text" value={this.state.vehiculo.rastreador} id="rastreador" onChange={this.handleInputChange} required />
                                    </div>


                                </div>

                                <div className="inputSection wrap">
                                    <button type="submit" className="success">Guardar</button>
                                </div>
                            </form>
                        </div>
                    </div >
                </div >
            )


        )
    }
}

export default withRouter(Vehiculo)