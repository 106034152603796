import React from 'react'
import { withRouter } from 'react-router-dom'
import control, { alertDanger, alertSuccess, form } from '../../../core/core'
import RoundSpinner from '../../../components/roundSpinner/roundSpinner'
import lista_idiomas from '../../../components/storage/idiomas/idiomas'
import contratos_trabajadores from '../../../components/storage/contratos/contratosTrabajadores'

import { Switch } from 'react-switch-input'
import lista_proveedores from '../../../components/storage/proveedores/proveedores'



class Trabajador extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            trabajador: {
                idTrabajador: 0,
                nombre: ''
            },
            idTrabajador: 0,
            idiomas: []
        }

        this.handleInputChange = this.handleInputChange.bind(this)

    }


    async handleInputChange(event) {
        const target = event.target
        let value = target.value

        if (target.type === 'checkbox') value = target.checked


        await this.setState(prevState => ({
            ...prevState,
            trabajador: {
                ...prevState.trabajador,
                [target.id]: value
            }
        }))

    }



    async componentDidMount() {
        let { idTrabajador } = this.props.match.params


        idTrabajador = parseFloat(idTrabajador)

        if (idTrabajador > 0) {
            const detallesTrabajador = await control({ api: 'trabajadores/trabajadores', request: 'detallesTrabajador', data: idTrabajador })

            if (detallesTrabajador.respuesta) {

                this.setState({ trabajador: detallesTrabajador.respuesta })
            }
        }

        this.setState({
            idiomas: await lista_idiomas(),
            loading: false
        })
    }



    async updateTrabajador(e) {
        e.preventDefault()
        let update = await form({
            form: 'updateTrabajador',
            api: 'trabajadores/trabajadores'
        })

        if (!update.respuesta || !update.respuesta.status) return alertDanger(`Ha ocurrido un error. ${update.respuesta.mensaje !== null ? update.respuesta.mensaje : ''}`)

        let mensaje
        if (update.respuesta.nuevoTrabajador) {

            this.setState({ idTrabajador: update.respuesta.nuevoTrabajador }
            )
            mensaje = 'creado'

        } else {

            mensaje = 'actualizado'

        }

        alertSuccess(`Trabajador ${mensaje} con éxito`)
    }
    render() {

        return (
            this.state.loading ? (
                <RoundSpinner />
            ) : (
                <div className="grid2">
                    <div className="appElement">
                        <div className="appElementHeader">
                            {
                                this.state.trabajador.idTrabajador > 0 ? (
                                    `Modificar ${this.state.trabajador.nombre}`

                                ) : (
                                    'Registrar nuevo trabajador'
                                )
                            }
                        </div>
                        <div className="appElementContent">
                            <form id="updateTrabajador" onSubmit={e => this.updateTrabajador(e)}>


                                <input type="hidden" id="idTrabajador" value={this.state.trabajador.idTrabajador} />
                                <input type="hidden" id="idUsuario" value={this.state.trabajador.idUsuario} />
                                <div className="inputSection wrap">
                                    <label>Nombre</label>
                                    <input placeholder="Nombre del trabajador" type="text" value={this.state.trabajador.nombre} id="nombre" onChange={this.handleInputChange} required />
                                </div>
                                <div className="inputSection wrap">
                                    <label>Email</label>
                                    <input placeholder="E-mail del trabajador" type="email" value={this.state.trabajador.mail} id="mail" onChange={this.handleInputChange} required />
                                </div>
                                <div className="inputSection wrap">
                                    <label>Teléfono</label>
                                    <input placeholder="Teléfono del trabajador" type="number" value={this.state.trabajador.telefono} id="telefono" onChange={this.handleInputChange} required />
                                </div>
                                <div className="inputSection wrap">
                                    <label>Rol</label>
                                    <select placeholder="Rol del trabajador" value={this.state.trabajador.rol} id="rol" onChange={this.handleInputChange} required >
                                        <option value="">Selecciona el rol del trabajador</option>
                                        <option value="Conductor">Conductor</option>
                                        <option value="Guia">Guia</option>
                                    </select>
                                </div>


                                <div className="inputSection wrap">
                                    <button type="submit" className="success">Guardar</button>
                                </div>
                            </form>
                        </div>
                    </div >
                </div >
            )


        )
    }
}

export default withRouter(Trabajador)