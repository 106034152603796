
const urlBase = 'seniorback.nattule.com'



const crud = `https://${urlBase}/control/control.php`


export const cdn = `//cdn.${urlBase}/`



export default crud
