import React from "react";
import RoundSpinner from "../../../components/roundSpinner/roundSpinner";
import control, { alert, alertDanger, form, modal } from "../../../core/core";

class FormularioParticipante extends React.Component {

    constructor(props) {
        super(props)


        this.state = {
            loading: true,
            idParticipante: parseFloat(this.props.idParticipante),
            itinerarios: [],
            participante: {
                itinerario: 0
            }
        }


        this.handleInputChange = this.handleInputChange.bind(this)


    }


    handleInputChange(event) {
        const target = event.target
        let value = target.value

        if (target.type === 'checkbox') value = target.checked


        this.setState(prevState => ({
            ...prevState,
            participante: {
                ...prevState.participante,
                [target.id]: value
            }
        }))
    }




    async componentDidMount() {
        const itinerarios = await control({ api: 'itinerarios/itinerarios', request: 'listarItinerarios' })
        if (itinerarios.respuesta) this.setState({ itinerarios: itinerarios.respuesta })


        if (this.state.idParticipante > 0) {

            const participante = await control({ api: 'participantes/participantes', request: 'detallesParticipante', data: this.state.idParticipante })

            if (!participante.respuesta) return alertDanger('No se encontró el participante')

            this.setState({ participante: participante.respuesta, loading: false })

        }

        this.setState({ loading: false })
    }

    async procesarParticipante(e) {
        e.preventDefault()

        const process = await form({ form: 'procesarParticipante', api: 'participantes/participantes' })

        if (!process.respuesta.success) return alert({ tipo: 0, mensaje: 'No pudo guardar los cambios. Por favor, comprueba los campos' })


        if (!process.respuesta.nuevo) return alert({ tipo: 1, mensaje: 'Participante actualizado' })

        this.setState({ idParticipante: process.respuesta.idParticipante })

        alert({ tipo: 1, mensaje: 'Participante creado correctamente' })

    }

    render() {
        return (
            this.state.loading ? (
                <RoundSpinner />
            ) : (
                <>

                    <form id="procesarParticipante" onSubmit={e => this.procesarParticipante(e)}>

                        <h2 className="center">Reserva</h2>

                        <input type="hidden" id="idParticipante" value={this.state.idParticipante} />


                        <div className="grid2">

                            <div className="inputSection wrap">
                                <label htmlFor="registro">Registro</label>
                                <input type="text" id="registro" placeholder="Registro" value={this.state.participante.registro} onChange={this.handleInputChange} required />
                            </div>
                            <div className="inputSection wrap">
                                <label htmlFor="expediente">Expediente</label>
                                <input type="text" id="expediente" placeholder="Expediente" value={this.state.participante.expediente} onChange={this.handleInputChange} required />
                            </div>

                            <div className="inputSection wrap">
                                <label htmlFor="comarca">Comarca</label>
                                <input type="text" id="comarca" placeholder="Comarca" value={this.state.participante.comarca} onChange={this.handleInputChange} required />
                            </div>


                            <div className="inputSection wrap">
                                <label htmlFor="plazas">Plazas</label>
                                <input type="number" min="1" id="plazas" placeholder="Plazas" value={this.state.participante.plazas} onChange={this.handleInputChange} required />
                            </div>

                            <div className="inputSection wrap">
                                <label htmlFor="estado">Estado</label>
                                <select id="estado" placeholder="Estado" value={this.state.participante.estado} onChange={this.handleInputChange} required >
                                    <option value={undefined}>Selecciona un estado</option>
                                    <option value="1">Pendiente</option>
                                    <option value="2">Confirmado falta de pago</option>
                                    <option value="3">Confirmado</option>
                                    <option value="4">Cancelado</option>
                                </select>
                            </div>

                            <div className="inputSection wrap">
                                <label htmlFor="admitida">Admitida</label>

                                <select id="admitida" placeholder="admitida" value={this.state.participante.admitida} onChange={this.handleInputChange} required >
                                    <option value={undefined}>Es admitida?</option>
                                    <option value="SI">Si</option>
                                    <option value="NO">NO</option>
                                </select>
                            </div>

                            <div className="inputSection wrap">
                                <label htmlFor="itinerario">Selecciona el itinerario</label>
                                <select id="itinerario" value={this.state.participante.itinerario} onChange={this.handleInputChange} required>
                                    {
                                        this.state.itinerarios.length > 0 ? (
                                            this.state.itinerarios.map(e => {
                                                return <option value={e.idItinerario}>{e.titulo}</option>
                                            })
                                        ) : null
                                    }
                                </select>
                            </div>

                            <div className="inputSection wrap">
                                <label htmlFor="familiar">Familiar</label>
                                <input type="text" id="familiar" placeholder="familiar" value={this.state.participante.familiar} onChange={this.handleInputChange} required />
                            </div>



                        </div>


                        <h2 className="center">Participante</h2>


                        <div className="grid2">


                            <div className="inputSection wrap">
                                <label htmlFor="nombre">Nombre</label>
                                <input type="text" id="nombre" placeholder="Nombre" value={this.state.participante.nombre} onChange={this.handleInputChange} required />
                            </div>


                            <div className="inputSection wrap">
                                <label htmlFor="apellido">Apellido</label>
                                <input type="text" id="apellido" placeholder="Apellido" value={this.state.participante.apellido} onChange={this.handleInputChange} required />
                            </div>


                            <div className="inputSection wrap">
                                <label htmlFor="segundoApellido">Segundo apellido</label>
                                <input type="text" id="segundoApellido" placeholder="Segundo apellido" value={this.state.participante.segundoApellido} onChange={this.handleInputChange} required />
                            </div>

                            <div className="inputSection wrap">
                                <label htmlFor="dni">DNI</label>
                                <input type="text" id="dni" placeholder="DNI" value={this.state.participante.dni} onChange={this.handleInputChange} required />
                            </div>



                            <div className="inputSection wrap">
                                <label htmlFor="fechaNacimiento">Fecha Nacimiento</label>
                                <input type="date" id="fechaNacimiento" placeholder="Fecha Nacimiento" value={this.state.participante.fechaNacimiento} onChange={this.handleInputChange} required />
                            </div>

                            <div className="inputSection wrap">
                                <label htmlFor="telefono">Teléfono</label>
                                <input type="text" id="telefono" placeholder="Teléfono" value={this.state.participante.telefono} onChange={this.handleInputChange} required />
                            </div>



                            <div className="inputSection wrap">
                                <label htmlFor="municipio">Municipio</label>
                                <input type="text" id="municipio" placeholder="Municipio" value={this.state.participante.municipio} onChange={this.handleInputChange} required />
                            </div>

                        </div>




                        <h2 className="center">Acompañante</h2>

                        <div className="grid2">

                            <div className="inputSection wrap">
                                <label htmlFor="acompaniante_nombre">Nombre</label>
                                <input type="text" id="acompaniante_nombre" placeholder="Nombre" value={this.state.participante.acompaniante_nombre} onChange={this.handleInputChange} />
                            </div>


                            <div className="inputSection wrap">
                                <label htmlFor="acompaniante_apellido">Apellido</label>
                                <input type="text" id="acompaniante_apellido" placeholder="Apellido" value={this.state.participante.acompaniante_apellido} onChange={this.handleInputChange} />
                            </div>


                            <div className="inputSection wrap">
                                <label htmlFor="acompaniante_segundoApellido">Segundo apellido</label>
                                <input type="text" id="acompaniante_segundoApellido" placeholder="Segundo apellido" value={this.state.participante.acompaniante_segundoApellido} onChange={this.handleInputChange} />
                            </div>

                            <div className="inputSection wrap">
                                <label htmlFor="acompaniante_dni">DNI</label>
                                <input type="text" id="acompaniante_dni" placeholder="DNI" value={this.state.participante.acompaniante_dni} onChange={this.handleInputChange} />
                            </div>



                            <div className="inputSection wrap">
                                <label htmlFor="acompaniante_fechaNacimiento">Fecha Nacimiento</label>
                                <input type="date" id="acompaniante_fechaNacimiento" placeholder="Fecha Nacimiento" value={this.state.participante.acompaniante_fechaNacimiento} onChange={this.handleInputChange} />
                            </div>

                            <div className="inputSection wrap">
                                <label htmlFor="acompaniante_telefono">Teléfono</label>
                                <input type="text" id="acompaniante_telefono" placeholder="Teléfono" value={this.state.participante.acompaniante_telefono} onChange={this.handleInputChange} />
                            </div>



                            <div className="inputSection wrap">
                                <label htmlFor="acompaniante_municipio">Municipio</label>
                                <input type="text" id="acompaniante_municipio" placeholder="Municipio" value={this.state.participante.acompaniante_municipio} onChange={this.handleInputChange} />
                            </div>

                        </div>


                        <h2 className="center">Otros detalles</h2>

                        <div className="grid2">
                            <div className="inputSection wrap">
                                <label htmlFor="datosFamiliarDiscapacidad">Datos familiar discapacidad</label>
                                <textarea type="text" id="datosFamiliarDiscapacidad" placeholder="Datos familiar discapacidad" value={this.state.participante.datosFamiliarDiscapacidad} onChange={this.handleInputChange} />
                            </div>
                            <div className="inputSection wrap">
                                <label htmlFor="motivoExclusion">Motivo de excilsion</label>
                                <textarea type="text" id="motivoExclusion" placeholder="Motivo de excilsion" value={this.state.participante.motivoExclusion} onChange={this.handleInputChange} />
                            </div>
                        </div>


                        <div className="center">
                            <button type="submit" className="success">
                                Guardar
                            </button>
                        </div>
                    </form>
                </>
            )
        )
    }
}


export default function formularioParticipante(p = { nombre: 'Formulario de participante', idParticipante: 0 }) {
    return modal({
        header: p.nombre,
        contenido: <FormularioParticipante idParticipante={p.idParticipante} />
    })
}