import React from 'react'
import control, { alertDanger, alertSuccess, form, modal, ocultarModal, setLocal } from '../../../core/core'
import RoundSpinner from '../../../components/roundSpinner/roundSpinner'
import Table from '../../../components/table/table'
import headerSection from '../../../components/headerSection'

import { GrEdit } from "react-icons/gr";
import listaContratos from '../../../components/storage/contratos/listaContratos'


class Contratos extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            contratos: []
        }
    }


    async componentDidMount() {
        setLocal({ item: 'contratos', value: false })

        this.setState({ contratos: await listaContratos() })

        this.setState({ loading: false })
    }



    setInLocal() {
        setLocal({ item: 'contratos', value: this.state.contratos })
    }


    async eliminarcontrato(idContrato) {
        const eliminar = await control({ api: 'contratos/contratos', request: 'eliminarContrato', data: idContrato })

        if (eliminar.respuesta) {

            const contratos = this.state.contratos.filter(e => parseFloat(e.idContrato) !== parseFloat(idContrato))

            await this.setState(prevState => ({
                ...prevState,
                contratos: contratos
            }))


            this.setInLocal()

            ocultarModal()
            alertSuccess('Contrato eliminado')

        } else {
            alertDanger('No se pudo eliminar el contrato')
        }
    }

    async procesarFormulario(e) {
        e.preventDefault()

        let editarContrato = await form({ api: 'contratos/contratos', form: 'updateContrato' })
        editarContrato = editarContrato.respuesta

        if (editarContrato.status) {

            if (editarContrato.nuevoContrato) {


                await this.setState(prevState => ({
                    ...prevState,
                    contratos: [...prevState.contratos, editarContrato.detallesContrato]
                }))

                alertSuccess('Contrato creado correctamente')


            } else {

                let contratos = this.state.contratos
                const index = contratos.findIndex(e => parseFloat(e.idContrato) === parseFloat(editarContrato.detallesContrato.idContrato))

                if (~index) contratos[index] = editarContrato.detallesContrato

                await this.setState(prevState => ({
                    ...prevState,
                    contratos: contratos
                }))


                alertSuccess('Contrato editado correctamente')



            }

            this.setInLocal()
            ocultarModal()

        } else {
            alertDanger('Ha ocurrido un error al guardar los cambios')
        }
    }


    confirmarEliminarcontrato(contrato) {


        return modal({
            header: 'Eliminiar contrato',
            contenido: <>
                <h2>¿ Quieres eliminar el contrato {contrato.titulo} ?</h2>
                <button type="button" className="outlineDanger" onClick={() => this.eliminarcontrato(contrato.idContrato)}>Si eliminar</button>
                <button type="button" className="success" onClick={() => ocultarModal()}>No eliminar</button>
            </>
        })
    }
    formularioContrato(d = {
        idContrato: 0,
        titulo: ''
    }) {
        return (
            <>
                <form id="updateContrato" onSubmit={e => this.procesarFormulario(e)}>

                    <input type="hidden" id="idContrato" value={d.idContrato} />

                    <div className="inputSection wrap">
                        <label>Nombre de la contrato</label>
                        <input type="text" id="titulo" placeholder="Ingresa el nombre de la contrato" defaultValue={d.titulo} required />
                    </div>
                    <div className="inputSection wrap">
                        <label>Tipología del contrato</label>
                        <select id="tipoContrato" defaultValue={d.tipoContrato} required>
                            <option value="1">Trabajadores</option>
                            <option value="2">Proveedores</option>
                        </select>
                    </div>

                    <div className="inputSection">
                        <button type="submit" className="success">
                            Guardar
                        </button>
                        <button onClick={() => ocultarModal()} className="outlineDanger">
                            Cancelar
                        </button>
                        {
                            d.idContrato > 0 ? (
                                <>
                                    <button onClick={() => this.confirmarEliminarcontrato(d)}>
                                        Eliminar
                                    </button>
                                </>
                            ) : (
                                null
                            )
                        }
                    </div>
                </form>
            </>
        )
    }
    editarContrato(contrato) {
        return modal(
            {
                header: 'Editar contrato',
                contenido: this.formularioContrato(contrato)
            }
        )
    }
    nuevoContrato() {
        return modal(
            {
                header: 'Nuevo contrato',
                contenido: this.formularioContrato({ idContrato: 0 })
            }
        )
    }
    render() {

        return (
            <>
                {
                    headerSection('contratos')
                }

                <div className="appElement">
                    <button type="button" className="outlineSuccess" onClick={() => this.nuevoContrato()}>
                        + Nuevo contrato
                    </button>
                    {
                        Table({
                            id: 'contratos',
                            thead: [{
                                'title': 'Titulo'
                            }, {
                                'title': ' '
                            }
                            ],
                            tbody: this.state.loading ? (
                                <RoundSpinner />
                            ) : (
                                this.state.contratos.length > 0 ? (
                                    this.state.contratos.map(e => {
                                        return (
                                            <tr>

                                                <td>
                                                    {e.titulo}
                                                </td>
                                                <td>
                                                    <button type="button" className="outlineSuccess" onClick={() => this.editarContrato(e)}>
                                                        <GrEdit />
                                                    </button>

                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No se encontraron contratos</td>
                                    </tr>
                                )
                            )
                        })
                    }
                </div>

            </>
        )

    }
}
export default Contratos