import { LogoWhatsapp } from "react-ionicons";

export default function WpButton() {
    return (
        <a href="https://wa.me/+34627628080" title="Contactar con Nattule">


<button className="wpButton" >
            
            <LogoWhatsapp/>
        </button>
        </a>
    )
}