import React from 'react'
import './navSide.css'
import NavSideAdmin from './navSideByUser/navSideAdmin'


export default class NavSide extends React.Component {

    constructor(props) {
        super(props)


        this.state = {
            loading: true,
            isLogged: false,
            _t: this.props._t ?? false
        }
    }

    componentDidMount() {

        this.setState({ loading: false })
    }
    render() {

        return (

            this.state._t === 1 ? (
                <div className="sideBar">
                    
                    <div className="sideBarMenu">

                        {
                            this.state._t === 1 ? (
                                <NavSideAdmin />
                            ) : null
                        }

                    </div>
                </div>
            ) : null




        )
    }
}