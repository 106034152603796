import { alert, readFile } from "../core/core"
import * as XLSX from 'xlsx'

async function readExcel(files){
    let file = await readFile(files,'arrayBuffer')
    if(!file) return alert({tipo:0,mensaje:'No se pudo leer el archivo'})
    file = file[0]

    let data = new Uint8Array(file.content)

    const workBook = XLSX.read(data,{type:'array'})
let hojas = []
    workBook.SheetNames.forEach(sheetName => {
        let XL_row_object = XLSX.utils.sheet_to_row_object_array(workBook.Sheets[sheetName])

        hojas.push({
            data:XL_row_object,
            sheetName
        })

    })
 return hojas
}

export default readExcel