import control, { local, setLocal } from "../../../core/core"

export default async function vehiculos_marca(){
    let marcas = local('vehiculos_marcas')

     if(!marcas){
         marcas = await control({ api: 'vehiculos/marcasYmodelos', request: 'vehiculos_marcas' })
         marcas = marcas.respuesta ? marcas.respuesta : []

         setLocal({item:'vehiculos_marcas',value:marcas })

     }

    return marcas 

}