import control, { local, setLocal } from "../../../core/core"

export default async function vehiculos_modelos(){
    let modelos = local('vehiculos_modelos')
     if(!modelos){
         modelos = await control({ api: 'vehiculos/marcasYmodelos', request: 'vehiculos_modelos' })
         modelos = modelos.respuesta

         setLocal({item:'vehiculos_modelos',value:modelos})
     }

    return modelos ? modelos : []

}