import React from 'react'
import { withRouter } from 'react-router'
import RoundSpinner from '../../../components/roundSpinner/roundSpinner'
import { local } from '../../../core/core'
import EditarItinerario from './editarItinerario'
import VistaItinerario from './vistaItinerario'





class Itinerario extends React.Component{

    state = {
        loading:true,
        _t:parseFloat(local('_t')),
        idItinerario:0

    }

    componentDidMount(){
        const {idItinerario} = this.props.match.params


        this.setState({idItinerario,loading:false})

    }

    render(){
        return(
            this.state.loading ? (
                <RoundSpinner/>
            ):(
                this.state._t === 1 ? (
                    <EditarItinerario idItinerario={this.state.idItinerario}/>
                ):(
                    <VistaItinerario idItinerario={this.state.idItinerario}/>
                )
            )
        )
    }
}


export default withRouter(Itinerario)