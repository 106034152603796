import control, { local, setLocal } from "../../../core/core"

export default async function lista_idiomas(){
    let idiomas = local('lista_idiomas')

     if(!idiomas){
         idiomas = await control({ api: 'idiomas/idiomas', request: 'lista_idiomas' })
         idiomas = idiomas.respuesta ? idiomas.respuesta : []

         setLocal({item:'lista_idiomas',value:idiomas })

     }

    return idiomas 

}