import React from 'react'
import headerSection from '../../../components/headerSection'
import control, { alertDanger, alertSuccess, form, validatePassword } from '../../../core/core'



export default class MiPerfil extends React.Component {

    constructor() {
        super()
        this.state = {
            loading: true,
            isLogged: false,
            perfil: {},
            password: {}
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleInputChangePassword = this.handleInputChangePassword.bind(this)
    }

    handleInputChange(event) {
        const target = event.target
        let value = target.value

        if (target.type === 'checkbox') value = target.checked


        this.setState(prevState => ({
            ...prevState,
            perfil: {
                ...prevState.perfil,
                [target.id]: value
            }
        }))
    }




    handleInputChangePassword(event) {
        const target = event.target
        let value = target.value

        if (target.type === 'checkbox') value = target.checked


        this.setState(prevState => ({
            ...prevState,
            password: {
                ...prevState.password,
                [target.id]: value
            }
        }))
    }


    async componentDidMount() {
        const perfil = await control({ api: 'perfil/miPerfil', request: 'miPerfil' })

        if(!perfil.respuesta) return false
        this.setState({ perfil: perfil.respuesta, loading: false })
    }


    async editarPerfil(e) {
        e.preventDefault()

        const update = await form({ form: 'editarPerfil', api: 'perfil/miPerfil' })
                
        if (!update.respuesta || !update.respuesta.status) return alertDanger(update.respuesta.mensaje ?? 'No se pudo editar el perfil')

        alertSuccess('Perfil actualizado!!')
    }

    async editarPassword(e) {
        e.preventDefault()

        const validate = validatePassword(this.state.password.pass ?? '')
        
        if (validate){

            const update = await form({ form: 'editarPassword', api: 'perfil/miPerfil' })

            if (!update.respuesta) return alertDanger('No se pudo actualizar la contraseña')
    
            alertSuccess('Contraseña actualizada!!')
    
        }
    }

    render() {

        return (<>
            {
                headerSection('Mi perfil')
            }
            <div className="grid2">
                <div className="appElement">
                    <div className="appElementHeader">
                        Editar perfil
                    </div>
                    <div className="appElementContent">
                        <form id="editarPerfil" onSubmit={e => this.editarPerfil(e)}>
                            <div className="inputSection wrap">
                                <label>Nombre:</label>
                                <input type="text" placeholder="Introduce tu nombre" id="nombre" value={this.state.perfil.nombre} onChange={this.handleInputChange} required/>
                            </div>
                            <div className="inputSection wrap">
                                <label>Apellido:</label>
                                <input type="text" placeholder="Introduce tu Apellido" id="apellido" value={this.state.perfil.apellido} onChange={this.handleInputChange} />
                            </div>
                            <div className="inputSection wrap">
                                <label>E-mail:</label>
                                <input type="email" placeholder="Introduce tu e-mail" id="mail" value={this.state.perfil.mail} onChange={this.handleInputChange} required/>
                            </div>
                            <div className="inputSection wrap">
                                <label>Teléfono:</label>
                                <input type="number" minLength="9" placeholder="Introduce aquí tu teléfono" id="telefono" value={this.state.perfil.telefono} onChange={this.handleInputChange} />
                            </div>
                            <div className="inputSection wrap">
                                <label>Dirección:</label>
                                <input type="text" placeholder="Dirección" id="direccion" value={this.state.perfil.direccion} onChange={this.handleInputChange} />
                            </div>
                            <div className="inputSection wrap">
                                <button type="submit" className="success">Guardar perfil</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="appElement">

                    <div className="appElementHeader">
                        Editar contraseña
                    </div>
                    <div className="appElementContent">
                        <form id="editarPassword" onSubmit={e => this.editarPassword(e)}>
                            <div className="inputSection wrap">
                                <label>Contraseña nueva:</label>
                                <input type="password" placeholder="Introduce tu nueva contraseña" id="pass" value={this.state.password.pass} onChange={this.handleInputChangePassword} />
                            </div>
                            <div className="inputSection wrap">
                                <label>Repite la contraseña nueva:</label>
                                <input type="password" minLength="9" placeholder="Repite la contraseña nueva" id="repPass" value={this.state.password.repPass} onChange={this.handleInputChangePassword} />
                            </div>
                            <div className="inputSection wrap">
                                <button type="submit" className="success">Actualizar contraseña</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

        )
    }
}