import React from "react"
import control, { alert, ocultarModal } from "../../../core/core"

export default class FormularioImportarRegistrosParticipantes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            registros: this.props.registros,
            actividades: this.props.itinerarios,
            preguntarConfirmacion: false,
            actividad: {},
            filtroActividades: this.props.itinerarios,
            terminoBusquedaActividad: '',
            loading: true,
            mensajeError: '',
            showError: false,
            lineaError: 0
        }
    }
    async componentDidMount() {
        this.setState({ loading: false })

    }

    async seleccionarActividad(actividad) {
        await this.setState({ actividad })
        this.setState({ preguntarConfirmacion: true })
    }


    async importarRegistros() {

        alert({ tipo: 1, mensaje: 'Estamos importando los registros' })

        let error = false
        let i = 1
        let registros = this.state.registros.reverse().map(e => {

            if(error) return false
            e.itinerario = this.state.actividad.idItinerario


            const validar = validarParticipante(e)
            if (!validar.status) {
                error = true
                this.setState({ mensajeError: validar.mensaje, showError: true, lineaError: i+2 })
                return alert({ tipo: 0, mensaje: `Se encontraron erroes en los registros` })
                
            }
            i++
            return e

        })

        if (!error) {
            const importacion = await control({ api: 'participantes/participantes', request: 'importarParticipantesExcel', data: registros })

            if (!importacion.respuesta) return alert({ tipo: 0, mensaje: 'Ha ocurrido un error en la importación de los registros.Por favor comprueba que el archivo y sus datos tienen el formato correcto' })

            alert({ tipo: 1, mensaje: 'Importación correcta!' })


            ocultarModal()
        }


    }

    filtrarActividades(v) {

        const actividades = this.state.actividades.filter(e => e.titulo.includes(v))

        this.setState({ terminoBusquedaActividad: v, filtroActividades: actividades ?? [] })
    }

    render() {
        return (
            this.state.loading ? (
                <div className="spinner"></div>
            ) : (

                this.state.registros.length === 0 ? (
                    <>
                        <h2>
                            No se encotraron registros
                        </h2>
                    </>
                ) : (
                    this.state.showError ? (
                        <>
                            <h2>Se encontró un error en la línea Número: {this.state.lineaError }</h2>

                            <h1>{this.state.mensajeError}</h1>

                            <p>Por favor cierra este mensaje y corrige el archivo antes de intentarlo nuevamente.</p>
                        </>
                    ) : (
                        this.state.preguntarConfirmacion ? (

                            <>
                                <h2>Se importarán {this.state.registros.length} registros en {this.state.actividad.nombre}</h2>
                                <button type="button" className="btnForm success" onClick={() => this.importarRegistros()}>Importar</button>
                                <button type="button" className="btnForm danger" onClick={() => this.setState({ preguntarConfirmacion: false })}>Cambiar actividad</button>
                                <button type="button" className="btnForm" onClick={() => ocultarModal()}>Cancelar importación</button>
                            </>
                        ) : (

                            <>
                                <h1>Selecciona la que actividad corresponden los registros</h1>
                                <div className="inputSection wrap">
                                    <label>Buscar actividad</label>
                                    <input type="text" id="terminoBusquedaActividad" placeholder="Nombre de actividad, nombre del equipamiento, id de actividad" value={this.state.terminoBusquedaActividad} onChange={e => this.filtrarActividades(e.target.value)} />
                                </div>
                                {this.state.filtroActividades.length > 0 ? (
                                    this.state.filtroActividades.slice(0, 10).sort((a, b) => {
                                        if (a.nombre > b.nombre) return 1
                                        return -1
                                    }).map(e => {
                                        return (
                                            <div key={`actividad_${e.idItinerario}`} className="actividadSelect" onClick={() => this.seleccionarActividad(e)}>
                                                {e.titulo}
                                            </div>
                                        )

                                    })
                                ) : (
                                    <h2>
                                        No se encoontraron actividades
                                    </h2>
                                )}

                            </>
                        )
                    )
                )
            )



        )
    }
}


export function validarParticipante(e) {


/*     if (!e['comarca']) return { status: false, mensaje: 'Hay un error en la columna de comarca' }
    if (!e['nombre']) return { status: false, mensaje: 'Hay un error en la columna de nombre' }
    if (!e['telefono']) return { status: false, mensaje: 'Hay un error en la columna de teléfono' }
    if (!e['familiar']) return { status: false, mensaje: 'Hay un error en la columna de familiar' }
    if (!e['apellido']) return { status: false, mensaje: 'Hay un error en la columna de apellido' }
    if (!e['segundoApellido']) return { status: false, mensaje: 'Hay un error en la columna de segundo apellido' }
    if (!e['dni']) return { status: false, mensaje: 'Hay un error en la columna de DNI' }
    if (!e['municipio']) return { status: false, mensaje: 'Hay un error en la columna de municipio' }
    if (!e['plazas']) return { status: false, mensaje: 'Hay un error en la columna de plazas' }
    if (!e['estado']) return { status: false, mensaje: 'Hay un error en la columna de estado' }
    if (!e['fechaNacimiento']) return { status: false, mensaje: 'Hay un error en la columna de fecha de nacimiento' }
    if (!e['admitida']) return { status: false, mensaje: 'Hay un error en la columna de admitida' }
    if (!e['expediente']) return { status: false, mensaje: 'Hay un error en la columna de expediente' }
    if (!e['registro']) return { status: false, mensaje: 'Hay un error en la columna de registro' } */

    return { status: true }

}