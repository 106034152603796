import { geA } from "../core/core";

export default function pdfPrint(d) {

    let contenido = '<html><head><title>Imprimir</title>' +
        '<link rel="stylesheet" href="/style/print.css" type="text/css" media="print"/>' +
        '</head><body>';


    contenido += `<h1>Itinerario Mundo Senior</h1>`
    contenido += document.getElementById(d.idDiv).innerHTML +
        '</body></html>';

    let imprimir = window.open(d.fileName ? 'Imprimir ' + d.fileName : 'Imprimir');
    imprimir.document.write(contenido);
    
     setTimeout(()=>{
        imprimir.document.close();
        imprimir.focus();
        imprimir.print();
        imprimir.close();
    },500)  
        
        


    return true;
}