import React from 'react'
import { Link } from 'react-router-dom'
import headerSection from '../../../components/headerSection'
import RoundSpinner from '../../../components/roundSpinner/roundSpinner'
import Table from '../../../components/table/table'
import control, { local } from '../../../core/core'
import { GrEdit } from 'react-icons/gr'


export default class Itinerarios extends React.Component {


    state = {
        loading: true,
        itinerarios: [],
        _t:parseFloat(local('_t'))
    }

    async componentDidMount() {
        this.listarTrabajadores()
    }

    async listarTrabajadores() {
        this.setState({ loading: true })
        const itinerarios = await control({ api: 'itinerarios/itinerarios', request: 'listarItinerarios' })
        if (itinerarios.respuesta) {
            this.setState({ itinerarios: itinerarios.respuesta })

        }

        this.setState({
            loading: false
        })

    }
    render() {

        return (<>
            {
                headerSection('Rutas')
            }

            {
                this.state._t === 1 ? (

                    <div className="appElement">
                    <Link to="/itinerario/0">
                        <button type="button" className="outlineSuccess btnSm">+ Nuevo</button>
                    </Link>
                </div>
                ):(null)
            }

            <div className="appElement">

                {
                    Table({
                        id: 'Itinerarios',
                        thead: [{
                            'title': 'Título'
                        },{
                            'title': '  '
                        },
                        ],
                        tbody: this.state.loading ? (
                            <RoundSpinner />
                        ) : (
                            this.state.itinerarios && this.state.itinerarios.length > 0 ? (
                                this.state.itinerarios.map(e => {
                                    

                                    return (
                                        <tr>
                                           
                                            <td>{e.titulo}</td>
                                            <td>
                                                <Link to={`/itinerario/${e.idItinerario}`}>
                                                    <button type="button" className="outlineSuccess">
                                                        <GrEdit />
                                                    </button>
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })


                            ) : (
                                <tr>
                                    <td>No se encontraron rutas</td>
                                </tr>
                            )
                        )
                    })
                }
            </div>

        </>

        )
    }

}