import control, { local, setLocal } from "../../../core/core"


export default async function contratos_proveedores(){
    let contratos = local('contratos')

     if(!contratos){
         contratos = await control({ api: 'contratos/contratos', request: 'listar_contratos' })
         contratos = contratos.respuesta ? contratos.respuesta : []

         setLocal({item:'contratos',value:contratos })

     }

    return contratos.filter(e => parseFloat(e.tipoContrato) === 1)

}