import React from "react";
import brandLogo from "../../../components/bandLogo";
import RoundSpinner from "../../../components/roundSpinner/roundSpinner";
import control, { modal } from "../../../core/core";
import LiveMap from "../../../privateSide/views/liveMap/LiveMap";
import VistaItinerarioPublic from "../itinerario/vistaItinerario";
import Login from "../login/login";

export default class Home extends React.Component {


    state = {
        itinerarios: [],
        loading: true
    }




    async componentDidMount() {

        const itinerarios = await control({ api: 'publico/itinerarios', request: 'listarItinerarios' })


        this.setState({ itinerarios: itinerarios.respuesta ?? [], loading: false })
    }
    render() {

        const color = [
            'success',
            'info',
            'danger'
        ]
        return (
            <>


                <div className="topBar">
                    <div className="topBarMenuContainer">
                        <div className="brand">
                            {brandLogo()}

                        </div>
                        <div className="topBarMenu">
                            <div className="topNavMenuElement" onClick={() => modal({
                                header: 'Mapa en vivo',
                                contenido: <LiveMap />
                            })}>
                                Mapa en vivo

                            </div>
                        </div>

                    </div>


                    <div className="topNavUser">
                        <button type="button" className="outlineSuccess" onClick={() => modal({
                            header: 'Iniciar sesión',
                            contenido: <Login />
                        })}>
                            Iniciar sesión
                        </button>
                    </div>

                </div>

                <div className="homeListaItinerariosContainer grid4">


                    {
                        this.state.loading ? <RoundSpinner /> : (
                            this.state.itinerarios.length > 0 ? (
                                this.state.itinerarios.sort((a, b) => {
                                    if (a.titulo > b.titulo) return 1
                                    return -1
                                }).map(e => {

                                    const containerClass = color[Math.random() * (2 - 1) + 1]
                                    return (
                                        <div className={'listadoItinerarioHome ' + containerClass}>
                                            <div className="listadoItinerarioHomeTitulo">
                                                {e.titulo}
                                            </div>
                                            <div className="listadoItinerarioHomeFechas">
                                                <strong>{e.fechaInicio}</strong><span> - </span><strong>{e.fechaFin}</strong>
                                            </div>

                                            <div className="listadoItinerarioHomeButton">

                                                <button type="button" className="btnSm outlineSecundary" onClick={() => modal({
                                                    header: e.titulo,
                                                    contenido: <VistaItinerarioPublic itinerario={e.idItinerario}/>
                                                })}>
                                                    Ver itinerario
                                                </button>

                                            </div>
                                        </div>
                                    )
                                })
                            ) : (
                                null
                            )
                        )
                    }
                </div>


            </>
        )
    }
}