import React from "react";
import RoundSpinner from "../../../components/roundSpinner/roundSpinner";
import control, { alert } from "../../../core/core";
import { contacto } from "../../../privateSide/views/itinerario/vistaItinerario";

export default class VistaItinerarioPublic extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            idItinerario: this.props.itinerario,
            loading: true,
            itinerario: {},
            recursos: {}
        }
    }


    async componentDidMount() {

        const itinerario = await control({ api: 'publico/itinerarios', request: 'detallesItinerario', data: this.state.idItinerario })

        let recursos = await control({ api: 'itinerarios/itinerarios', request: 'recursosEditarItinerario' })


        if (!recursos.respuesta) alert({ tipo: 0, mensaje: 'No se han podido cargar todos los recursos' })

        this.setState({ recursos: recursos.respuesta ?? {}, itinerario: itinerario.respuesta ?? {}, loading: false })
    }

    render() {


        let guia1,
            guia2,
            conductor,
            vehiculo

        if (this.state.recursos.trabajadores) {
            guia1 = this.state.recursos.trabajadores.find(e => e.idTrabajador === this.state.itinerario.guia1)
            guia2 = this.state.recursos.trabajadores.find(e => e.idTrabajador === this.state.itinerario.guia2)
            conductor = this.state.recursos.trabajadores.find(e => e.idTrabajador === this.state.itinerario.conductor)
        }

        if (this.state.recursos.vehiculos) {
            vehiculo = this.state.recursos.vehiculos.find(e => e.idVehiculo === this.state.itinerario.vehiculo)
        }
        return (
            this.state.loading ? (
                <RoundSpinner />
            ) : (
                <>
                    <button type="button" className="danger" onClick={() => contacto()}>
                        Información de contacto
                    </button>


                    <hr></hr>

                    <div className="grid1">


                        <div className="grid2">
                            <div className="inputSection wrap">
                                {this.state.itinerario.titulo}
                            </div>
                            <div className="grid2">
                                <div className="inputSection">
                                    <strong>Salida </strong><br></br>
                                    <span>{this.state.itinerario.fechaInicio}</span>
                                </div>
                                <div className="inputSection">
                                    <strong>Llegada </strong><br></br>
                                    <span>{this.state.itinerario.fechaFin}</span>
                                </div>
                            </div>
                        </div>

                        <div className="grid1">
                            <div dangerouslySetInnerHTML={{ __html: this.state.itinerario.descripcion }}>
                            </div>
                        </div>
                    </div>

                    <hr></hr>

                    <div className="grid4" style={{gridGap:'15px'}}>


                        <div>
                            <strong>
                                Vehículo asignado
                            </strong>
                            <br></br>
                            <br></br>

                            {
                                vehiculo ? (
                                    <>
                                        {
                                            vehiculo.marca + ' - ' + vehiculo.matricula
                                        }
                                


                                    </>
                                ) : ' No se encontró un vehiculo asignado'
                            }

                        </div>


                        <div>
                            <strong>
                                Conductor
                            </strong>
                            <br></br>
                            <br></br>


                            {
                                conductor ? (
                                    <>
                                        {
                                            conductor.nombre 
                                        }
                                


                                    </>
                                ) : ' No se encontró un conductor asignado'
                            }


                        </div>

                        <div>
                            <strong>
                                Guia 1
                            </strong>
                            <br></br>
                            <br></br>


                            {
                                guia1 ? (
                                    <>
                                        {
                                            guia1.nombre 
                                        }
                                


                                    </>
                                ) : ' No se encontró un guia asignado'
                            }


                        </div>

                            
                        <div>
                            <strong>
                                Guia 2
                            </strong>
                            <br></br>
                            <br></br>


                            {
                                guia1 ? (
                                    <>
                                        {
                                            guia2.nombre 
                                        }
                                


                                    </>
                                ) : ' No se encontró un guia asignado'
                            }


                        </div>

                    </div>

                </>
            )
        )
    }
}