import React from "react";
import control from "../../../core/core";





export default class LiveMap extends React.Component {


    state = {
        vehiculos: []
    }


    async componentDidMount() {
        const vehiculos = await control({ api: 'vehiculos/vehiculos', request: 'listarVehiculosPorItinerario' })
        this.setState({ vehiculos: vehiculos.respuesta ?? [] })

    }
    render() {
        return (
            <>
                <h2 className="headerSection">Live map</h2>
                <div className="grid3">

                    <div>

                    </div>

                    <div>
                        <div className="inputSection">
                            <select id="units" >

                                <option value={undefined}>Selecciona un vehículo</option>


                                {
                                    this.state.vehiculos.sort((a, b) => {
                                        if (a.itinerario > b.itinerario) return 1
                                        return -1
                                    }).map(e => {

                                        return (
                                            <option value={e.rastreador}>{e.itinerario}</option>
                                        )
                                    })
                                }
                            </select>

                        </div>
                        <div id="map"></div>

                    </div>

                </div>


            </>

        )
    }
}

