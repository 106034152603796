import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Cuestionario from './views/cuestionario/cuestionario'
import Home from './views/home/home'
import Quizz from './views/quizz/quizz'

export default function PublicSide() {

    return (

        <Router>
            <Switch>
                <Route exact path="/" >
                    <Home />
                </Route>
                <Route exact path={["/cuestionario/:dniParticipante","/cuestionario"]} >
                    <Cuestionario />
                </Route>


                <Route exact path="/quizz/:idItinerario" >
                <Quizz />
            </Route >
            <Route exact path="/quizz" >
                <Quizz />
            </Route >

            </Switch>
        </Router>

    )
}