import React from 'react'
import { BuzzFeedQuiz } from "react-buzzfeed-quiz";
import "react-buzzfeed-quiz/lib/styles.css";
import { withRouter } from 'react-router';
import RoundSpinner from '../../../components/roundSpinner/roundSpinner';

class Quizz extends React.Component {

  constructor() {
    super()
    this.state = {
      points: 0,
      selectedQuizz: 0,
      loading: true,
      quizzFinished: false
    }

  }



  async componentDidMount() {
    const { idItinerario } = this.props.match.params

    if (idItinerario) this.setState({ selectedQuizz: parseFloat(idItinerario) })


    this.setState({ loading: false })

  }



  restartQuizz() {
    window.location.reload()
  }



  preguntas() {
    let preguntas = []


    switch (this.state.selectedQuizz) {

      case 1:case 6:case 7:case 9:
        preguntas = [
          {
            question: "¿Cuál es el arquitecto más importante del Renacimiento en la provincia de Jaén?",
            answerArrangement: "row",
            answers: [
              {
                answer: "Diego de Siloé",
                resultID: 0,
              },
              {
                answer: "Andrés de Vandelvira",
                resultID: 1,
                onAnswerSelection: () => this.incPoints()
              },
              {
                answer: "Francisco de Villapalpando",
                resultID: 2,
              }, {
                answer: "Enrique Egas",
                resultID: 3,
              }
            ],
          }, {
            question: "¿Cuándo fueron declaradas “Patrimonio de la Humanidad” Úbeda y Baeza?",
            answerArrangement: "row",
            answers: [
              {
                answer: "1967",
                resultID: 0,
              },
              {
                answer: "1980",
                resultID: 1,
              },
              {
                answer: "1999",
                resultID: 2,
              }, {
                answer: "2003",
                resultID: 3,
                onAnswerSelection: () => this.incPoints()
              }
            ],
          }, {
            question: "¿Cuál de estos monumentos acoge el Ayuntamiento de Úbeda?",
            answerArrangement: "row",
            answers: [
              {
                answer: "Iglesia de Santa María de los Reales Alcázares",
                resultID: 0,
              },
              {
                answer: "Palacio de las Cadenas",
                resultID: 1,
                onAnswerSelection: () => this.incPoints()
              },
              {
                answer: "Palacio del Marqués de Mancera",
                resultID: 2,
              }, {
                answer: "Antiguo Pósito",
                resultID: 3,
              }
            ],
          }, {
            question: "¿Cuál de estas ciudades tiene una antigua Universidad?",
            answerArrangement: "row",
            answers: [
              {
                answer: "Úbeda",
                resultID: 0,
              },
              {
                answer: "Baeza",
                resultID: 1,
                onAnswerSelection: () => this.incPoints()
              },
              {
                answer: "Linares",
                resultID: 2,
              }, {
                answer: "Sabiote",
                resultID: 3,
              }
            ],
          }, {
            question: "¿Qué edificio es la principal atracción turística de Sabiote?",
            answerArrangement: "row",
            answers: [
              {
                answer: "El castillo",
                resultID: 0,
                onAnswerSelection: () => this.incPoints()
              },
              {
                answer: "Las murallas",
                resultID: 1,
              },
              {
                answer: "La Catedral",
                resultID: 2,
              }, {
                answer: "El mercado",
                resultID: 3,
              }
            ],
          }
        ]
        break


        case 2:case 10:case 11:
        preguntas = [
          {
            question: "¿Cuándo cerraron las últimas minas activas del Distrito Minero?",
            answerArrangement: "row",
            answers: [
              {
                answer: "A mediados de siglo XX",
                resultID: 0,
              },
              {
                answer: "A finales del siglo XIX",
                resultID: 1,
              },
              {
                answer: "A finales del siglo XX",
                resultID: 2,
                onAnswerSelection: () => this.incPoints()
              }, {
                answer: "Aún hay minas en activo",
                resultID: 3,
              }
            ],
          }, {
            question: "¿Cuál era la principal extracción de las minas de Linares y La Carolina?",
            answerArrangement: "row",
            answers: [
              {
                answer: "Plomo",
                resultID: 0,
                onAnswerSelection: () => this.incPoints()
              },
              {
                answer: "Aluminio",
                resultID: 1,
              },
              {
                answer: "Plata",
                resultID: 2,
              }, {
                answer: "Carbón",
                resultID: 3,
              }
            ],
          }, {
            question: "¿De qué época es el yacimiento arqueológico de Cástulo?",
            answerArrangement: "row",
            answers: [
              {
                answer: "Íbero",
                resultID: 0,
              },
              {
                answer: "Romano",
                resultID: 1,
              },
              {
                answer: "Proto-cristiano",
                resultID: 2,
              }, {
                answer: "Todas son correctas",
                resultID: 3,
                onAnswerSelection: () => this.incPoints()
              }
            ],
          }, {
            question: "¿A quién se enfrentaron los ejércitos cristianos en la Batalla de las Navas de Tolosa?",
            answerArrangement: "row",
            answers: [
              {
                answer: "A los almorávides",
                resultID: 0,
              },
              {
                answer: "A los almohades",
                resultID: 1,
                onAnswerSelection: () => this.incPoints()
              },
              {
                answer: "Al ejército del reino de Granada",
                resultID: 2,
              }, {
                answer: "Al ejército del califato de Córdoba",
                resultID: 3,
              }
            ],
          }, {
            question: "¿Quién ganó la batalla de Bailén?",
            answerArrangement: "row",
            answers: [
              {
                answer: "Los españoles",
                resultID: 0,
                onAnswerSelection: () => this.incPoints()
              },
              {
                answer: "Los franceses",
                resultID: 1,
              },
              {
                answer: "No hubo una victoria clara",
                resultID: 2,
              }
            ],
          }
        ]
        break


      case 3:case 8:case 12:case 13:
        preguntas = [
          {
            question: "¿Sobre qué restos arqueológicos de época musulmana se levanta el palacio renacentista de Villardompardo?",
            answerArrangement: "row",
            answers: [
              {
                answer: "Una mezquita",
                resultID: 0,
              },
              {
                answer: "Un alcázar",
                resultID: 1,
              },
              {
                answer: "Una muralla",
                resultID: 2,
              }
            ],
          }, {
            question: "¿Cuándo se acabó de construir la Catedral de Jaén?",
            answerArrangement: "row",
            answers: [
              {
                answer: "En el siglo XIII",
                resultID: 0,
              },
              {
                answer: "En el siglo XVI",
                resultID: 1,
              },
              {
                answer: "En el siglo XVIII",
                resultID: 2,
              }, {
                answer: "En el siglo XIX",
                resultID: 3,
                onAnswerSelection: () => this.incPoints()
              }
            ],
          }, {
            question: "¿Cómo se llama el castillo que hoy ocupa el parador de turismo de Jaén?",
            answerArrangement: "row",
            answers: [
              {
                answer: "Castillo de Yedra",
                resultID: 0,
              },
              {
                answer: "Castillo de Santa Catalina",
                resultID: 1,
                onAnswerSelection: () => this.incPoints()
              },
              {
                answer: "Castillo de Burgalimar",
                resultID: 2,
              }, {
                answer: "Castillo de la Mota",
                resultID: 3,
              }
            ],
          }, {
            question: "¿De qué cultura es el Oppidum de Puente Tablas?",
            answerArrangement: "row",
            answers: [
              {
                answer: "Romana",
                resultID: 0,
              },
              {
                answer: "Íbera",
                resultID: 1,
                onAnswerSelection: () => this.incPoints()
              },
              {
                answer: "Cartaginesa",
                resultID: 2,
              }, {
                answer: "Tartessos",
                resultID: 3,
              }
            ],
          }, {
            question: "¿Cuál de estas especies animales no se puede ver en el parque Periurbano de Otiñar?",
            answerArrangement: "row",
            answers: [
              {
                answer: "Jabalí",
                resultID: 0,
              },
              {
                answer: "Cabra montesa",
                resultID: 1,
              },
              {
                answer: "Ardilla",
                resultID: 2,
              }, {
                answer: "Lince ibérico",
                resultID: 3,
                onAnswerSelection: () => this.incPoints()
              }
            ],
          }
        ]
        break

      case 4:case 14:
        preguntas = [
          {
            question: "¿Cómo se llama el Centro de visitantes del Parque Natural de Cazorla, Segura y las Villas?",
            answerArrangement: "row",
            answers: [
              {
                answer: "No tiene nombre",
                resultID: 0,
              },
              {
                answer: "Torre del Vinagre",
                resultID: 1,
                onAnswerSelection: () => this.incPoints()
              },
              {
                answer: "Peal de Becerro",
                resultID: 2,
              }, {
                answer: "El Tranco",
                resultID: 3,
              }
            ],
          }, {
            question: "¿En qué localidad se encuentra el Museo Miguel Hernández-Josefina Manresa?",
            answerArrangement: "row",
            answers: [
              {
                answer: "Cazorla",
                resultID: 0,
              },
              {
                answer: "Castril",
                resultID: 1,
              },
              {
                answer: "Quesada",
                resultID: 2,
                onAnswerSelection: () => this.incPoints()
              }, {
                answer: "Jódar",
                resultID: 3,
              }
            ],
          }, {
            question: "¿Quién era Josefina Manresa?",
            answerArrangement: "row",
            answers: [
              {
                answer: "Una escritora de la generación del 27",
                resultID: 0,
              },
              {
                answer: "La esposa y musa de Miguel Hernández",
                resultID: 1,
                onAnswerSelection: () => this.incPoints()
              },
              {
                answer: "Una pintora modernista",
                resultID: 2,
              }, {
                answer: "El personaje principal de una novela",
                resultID: 3,
              }
            ],
          }, {
            question: "¿Cuál es el principal enterramiento íbero de la provincia de Jaén?",
            answerArrangement: "row",
            answers: [
              {
                answer: "la Cámara Sepulcral de Toya",
                resultID: 0,
                onAnswerSelection: () => this.incPoints()
              },
              {
                answer: "El yacimiento de Alarcos",
                resultID: 1,
              },
              {
                answer: "El Oppidum de Puente Tablas",
                resultID: 2,
              }, {
                answer: "La necrópolis ibérica de Tútugi",
                resultID: 3,
              }
            ],
          }, {
            question: "¿Qué tipo de embarcación se usa para los paseos guiados por el embalse del Tranco?",
            answerArrangement: "row",
            answers: [
              {
                answer: "Barca de remos",
                resultID: 0,
              },
              {
                answer: "Lancha motora",
                resultID: 1,
              },
              {
                answer: "Canoa",
                resultID: 2,
              }, {
                answer: "Barco solar",
                resultID: 3,
                onAnswerSelection: () => this.incPoints()
              }
            ],
          }
        ]
        break



      case 5:case 15:
        preguntas = [
          {
            question: "¿Qué función tenía el castillo de Santa Catalina, en Jaén, durante la guerra de la Independencia?",
            answerArrangement: "row",
            answers: [
              {
                answer: "Era el cuartel general español en la zona",
                resultID: 0,
              },
              {
                answer: "Era el cuartel general francés en la zona",
                resultID: 1,
                onAnswerSelection: () => this.incPoints()
              },
              {
                answer: "No tuvo ningún papel en la guerra",
                resultID: 2,
              }, {
                answer: "Fue destruido durante la guerra",
                resultID: 3,
              }
            ],
          }, {
            question: "¿Qué parte de Andújar fue declarada Bien de Interés Cultural en 2007?",
            answerArrangement: "row",
            answers: [
              {
                answer: "Las murallas",
                resultID: 0,
              },
              {
                answer: "Un castillo",
                resultID: 1,
              },
              {
                answer: "Su casco antiguo",
                resultID: 2,
                onAnswerSelection: () => this.incPoints()
              }, {
                answer: "El ayuntamiento",
                resultID: 3,
              }
            ],
          }, {
            question: "¿Qué fortaleza musulmana se conserva en Alcalá la Real?",
            answerArrangement: "row",
            answers: [
              {
                answer: "Castillo Calatravo",
                resultID: 0,
              },
              {
                answer: "Castillo de Tobaruelo ",
                resultID: 1,
              },
              {
                answer: "Fortaleza de la Mota",
                resultID: 2,
                onAnswerSelection: () => this.incPoints()
              }, {
                answer: "Fortaleza de la Iruela",
                resultID: 3,
              }
            ],
          }, {
            question: "En el castillo de Yedra hay una exposición sobre las costumbres de la zona. ¿Sabes en qué parte del edificio?",
            answerArrangement: "row",
            answers: [
              {
                answer: "La torre del homenaje",
                resultID: 0,
              },
              {
                answer: "El patio",
                resultID: 1,
              },
              {
                answer: "La capilla",
                resultID: 2,
              }, {
                answer: "Un anexo de la torre",
                resultID: 3,
                onAnswerSelection: () => this.incPoints()
              }
            ],
          }, {
            question: "La provincia de Jaén tiene la mayor concentración de castillos y fortalezas de Europa. ¿Sabes cuántos de estos edificios hay?",
            answerArrangement: "row",
            answers: [
              {
                answer: "56",
                resultID: 0,
              },
              {
                answer: "237",
                resultID: 1,
                onAnswerSelection: () => this.incPoints()
              },
              {
                answer: "1045",
                resultID: 2,
              }, {
                answer: "1045",
                resultID: 3,
              }
            ],
          }
        ]
        break



      default:
        break
    }

    return preguntas
  }


  incPoints() {
    let points = parseFloat(this.state.points)
    points++
    console.log(points)
    this.setState({ points })
  }


   
quizzFinished(){

  if(!this.state.quizzFinished) this.setState({quizzFinished:true})


}
  render() {
  
     return (

      this.state.loading ? (

        <RoundSpinner />

      ) : (

        this.state.selectedQuizz === 0 ? (
          <>
            <h2 className="headerSection">Selecciona un quizz</h2>
            <div className="appElement">

              <div className="grid3">

                <div></div>


                <div>

                  <div className="quizzSelector" onClick={() => this.setState({ selectedQuizz: 1 })}>
                    Ruta 1: Renacimiento del Sur.
                  </div>

                  <div className="quizzSelector" onClick={() => this.setState({ selectedQuizz: 2 })}>
                    Ruta 2: Distrito minero Linares – La Carolina.
                  </div>


                  <div className="quizzSelector" onClick={() => this.setState({ selectedQuizz: 3 })}>
                    Ruta 3: Jaén, capital del Paraíso Interior.
                  </div>

                  <div className="quizzSelector" onClick={() => this.setState({ selectedQuizz: 4 })}>
                    Ruta 6: Parque Natural de Cazorla, Segura y Las Villas: Museo Miguel Hernández – Cámara Sepulcral de Toya.
                  </div>

                  <div className="quizzSelector" onClick={() => this.setState({ selectedQuizz: 5 })}>
                    Ruta 8: Ruta Castillos y Batallas. Zona Sur.
                  </div>

                </div>


                <div></div>
              </div>


            </div>
          </>
        ) : (

          <>
          <BuzzFeedQuiz
            autoScroll={true}
            onResult={() => this.quizzFinished()}
            questions={this.preguntas()}
            results={[
              {
                title: "Tu puntaje es: " + this.state.points + "de " + this.preguntas().length,
                description: "",
                resultID: 0,
              },
              {
                title: "Tu puntaje es: " + this.state.points + "de " + this.preguntas().length,
                description: "",
                resultID: 1,
              },
              {
                title: "Tu puntaje es: " + this.state.points + "de " + this.preguntas().length,
                description: "",
                resultID: 2,
              },
            ]}
          />


            {
              this.state.quizzFinished ? (
                <div className="center">
                    <button type="button" className="success">
                      Ver respuestas correctas
                    </button>
                    <button type="button" className="outlineDanger" onClick={() => window.location.reload()}>
                      Volver a intentar
                    </button>
                </div>
              ):(
                null
              )
            }
          </>
        )

      )

    )


  }
}

export default withRouter(Quizz)