import { Link } from 'react-router-dom'


export default function NavSideAdmin() {

    return (
        <>

               <Link to="/itinerarios">
                <div className="sideBarMenuElement">
                    Rutas
                </div>
            </Link>


            <Link to="/trabajadores">
                <div className="sideBarMenuElement">
                    Trabajadores
                </div>
            </Link>


            <Link to="/participantes">
                <div className="sideBarMenuElement">
                    Participantes
                </div>
            </Link>

            <Link to="/vehiculos">
                <div className="sideBarMenuElement">
                    Vehículos
                </div>
            </Link>


            <Link to="/live-map">
                <div className="sideBarMenuElement">
                    Live map
                </div>
            </Link>

            <Link to="/mi-perfil">
                <div className="sideBarMenuElement">
                    Mi perfil
                </div>
            </Link>
        </>
    )
}