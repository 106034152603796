import { Link } from 'react-router-dom'
import { local } from '../../../core/core'


export default function NavSideTrabajador() {

    const _t = parseFloat(local('_t'))
    
    return (
        <>

            <Link to="/">
                <div className="topNavMenuElement">
                    Rutas
                </div>
            </Link>

           {
               _t === 2 ? (
                <Link to="/live-map">
                <div className="topNavMenuElement">
                    Live map
                </div>
            </Link>
               ):(
                   null
               )
           }
            <Link to="/mi-perfil">
                <div className="topNavMenuElement">
                    Mi perfil
                </div>
            </Link>
        </>
    )
}