import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import control, { local, logOut } from '../core/core'
import Login from '../publicSide/views/login/login'
import NavSide from './navSide/navSide'
import NavTop from './navTop/navTop'

import './privateSide.css'
import RouterAdmin from './routerByUser/routerAdmin'
import RouterTrabajador from './routerByUser/routerTrabajador'


export default class PrivateSide extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            isLogged: this.props.isLogged,
            sessionStatus: false,
            _t:0
        }

    }


    async componentDidMount() {

        if (!this.state.isLogged) return logOut()

        const checkSession = await control({ api: 'security/seguridad', request: 'checkSession' })

        if (!checkSession.respuesta) return logOut()

        this.setState({ sessionStatus: true, _t: local('_t'), loading: false })

    }


    render() {

        return (
            this.state.loading ? 'cargando' :
                (
                    this.state.isLogged && this.state.sessionStatus ? (
                        <>

                            <Router>
                                <NavTop _t={this.state._t}  />
                                <NavSide _t={this.state._t} />

                                <Switch>
                                    {
                                        this.state._t === 1 ? (
                                            <RouterAdmin />
                                        ) : this.state._t === 2 || this.state._t === 3 ? (
                                            <RouterTrabajador />
                                        ): null
                                    }


                                </Switch >
                            </Router >
                        </>
                    ) : (
                        <Login />

                    )
                )

        )
    }
}