import ReactDOM from 'react-dom';
import crud from './config'
import { CloseOutline } from 'react-ionicons'

export default async function control(data) {
    return new Promise((resolve) => {
        mostrarCargador()
        controlData(data).then(d => {
            let req = new XMLHttpRequest()
            //req.upload.onprogress = e => {}
            req.onload = () => resolve(controlLoad(req.responseText))
            req.onerror = () => resolve(false)
            req.open('POST', crud)
            req.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
            req.send(d)
        })

    })
}
let cargador = ge('#cargador'),
    modalHistory = [],
    modalStatus = 0,
    cargadorStatus = false



export function mostrarCargador() {
    cargadorStatus = true
    setTimeout(() => {
        if (cargadorStatus) cargador.style.display = 'block'
    }, 8000)

}
export function ocultarCargador() {
    cargadorStatus = false
    cargador.style.display = 'none'

}


export function login(data) {
    if (data.token) {
        window.location.href = '/'
    } else {
        alert({ 'tipo': 0, 'mensaje': 'Comprueba los campos' })
    }
}


export function setSesion(data,reload = false) {
    if (ge('#remember').checked) {
        for (const k in data) {
            setLocal({ item: k, value: data[k] })
        }
    } else {
        for (const k in data) {
            sessionLocal({ item: k, value: data[k] })
        }
    }


    if(reload) window.location.reload()
}

export function controlLoad(data) {
    try {
        data = JSON.parse(data)


        if (data.hasOwnProperty('status') && !data.status) {
            alert({ 'tipo': 0, 'mensaje': data.statusMensaje ?? 'Ha ocurrido un error' })

            if (data.hasOwnProperty('statusCode')) {
                if (data.statusCode === 1001) {
                    window.location = '/'
                }
            }


        }


    } catch (r) { }

    ocultarCargador()

    return data


}

export function setCookie(n, v, expire = false) {
    if (!expire) {
        expire = new Date(Date.now())
        expire = expire.getTime() + 30 * 24 * 60 * 60 * 1000
        expire = new Date(expire).toUTCString()
    }

    document.cookie = `${n}=${v};expires=${expire};pach=/`
}


export function validatePassword(password) {
    if (password.length < 6 || password.length > 30) {
        alert({ tipo: 0, mensaje: 'La contraseña debe de tener entre 6 y 30 caracteres' })
        return false
    }
    const required = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,30}$/

    if (password.match(required)) {
        alert({ tipo: 1, mensaje: '¡Buen trabajo! Esta contraseña es segura' })
        return true
    } else {
        alert({ tipo: 0, mensaje: 'La contraseña no es segura. Asegurate de que tenga entre 6 y 30 caracteres, al menos un número, al menos una letra mayúscula y una minúscula' })
        return false
    }

}

export async function controlData(data) {
    return new Promise((resolve, reject) => {
        let elementos = new FormData();
        if (data.files) {
            Array.from(data.files).forEach(e => {
                elementos.append('files', e)
            })

        }
        elementos.append('api', data.api ?? 'public')
        elementos.append('request', data.request ?? 'main')
        elementos.append('props', JSON.stringify(data.data) ?? false)
        elementos.append('auth', JSON.stringify(local('auth')) ?? false)

        resolve(elementos)


    })
}

export function form(d) {
    return new Promise((resolve) => {


        let elementos = geA(`#${d.form} input,#${d.form} textarea,#${d.form} select`),
            array = {},
            status = true,
            wrongInputs = geA('.wrongInput'),
            errores = 0

        if (wrongInputs) wrongInputs.forEach(e => e.classList.remove('wrongInput'))

        elementos.forEach(elemento => {
            switch (elemento.type) {
                case 'email':
                    if (elemento.value.indexOf('@') === -1) status = false
                    break
                case 'password':
                    if (elemento.value.length < 6) status = false
                    break
                case 'tel':
                    if (elemento.value.length < 9 && elemento.required) status = false
                    break
                case 'repPass':
                    if (elemento.value !== ge('#password').value) status = false
                    break
                case 'condiciones':
                    if (!elemento.checked) status = false
                    break
                default:
                    status = true
                    break
            }

            if (elemento.required && elemento.value.length < 1) status = false

            if (status) {

                if (elemento.type === 'checkbox') {
                    array[elemento.id] = elemento.checked
                } else if (elemento.type === 'file') {

                } else if (elemento.type === 'select-multiple') {
                    let selected = document.querySelectorAll(`#${d.form} #${elemento.id} option:checked`)
                    array[elemento.id] = Array.from(selected).map(el => el.value)
                } else {
                    array[elemento.id] = elemento.value

                }
            } else {
                elemento.classList.add('wrongInput')
                errores++
                return alert({ 'tipo': 0, 'mensaje': 'Comprueba los campos' })
            }
        })


        if (errores === 0) {
            let data = {}
            data['api'] = d.api
            data['request'] = d.form
            data['data'] = array
            control(data).then(r => resolve(r))
        } else {
            return alert({ 'tipo': 0, 'mensaje': 'Ha ocurrido un error con los campos' })
        }
        try {
            d.event.preventDefault()

        } catch (e) { }


    })


}

export function local(item) {
    if (window.localStorage.getItem(item) !== null && window.localStorage.getItem(item) !== 'false') {

        item = window.localStorage.getItem(item)

    } else if (window.sessionStorage.getItem(item) !== null && window.sessionStorage.getItem(item) !== 'false') {

        item = window.sessionStorage.getItem(item)

    } else {

        item = false

    }
    if (item === 'undefined' || item === 'null') {
        item = false
    } else {
        try {
            item = JSON.parse(item)
        } catch (e) { }
    }

    return item
}

export function setLocal(data) {
    if (data.value === 'undefined') data.value = false
    window.localStorage.setItem(data.item, JSON.stringify(data.value))
}
export function sessionLocal(data) {
    window.sessionStorage.setItem(data.item, JSON.stringify(data.value))
}



export function alertDanger(mensaje) {
    alert({ tipo: 0, mensaje: mensaje })
}

export function alertSuccess(mensaje) {
    alert({ tipo: 1, mensaje: mensaje })
}

export function alertInfo(mensaje) {
    alert({ tipo: 2, mensaje: mensaje })
}

export function alert(data) {
    var tipo = 'Message'
    switch (data.tipo) {
        case 0:
            tipo = 'danger'
            break
        case 1:
            tipo = 'success'
            break
        case 2:
            tipo = 'info'
            break
        default:
            break
    }

    if (ge('#alertSystem')) ge('#alertSystem').remove()

    let alerta = document.createElement('div')
    alerta.classList = `alert col-md-6 offset-md-3 ${tipo}`
    alerta.id = 'alertSystem'
    render({ 'parent': 'body', 'modo': 1, 'contenido': alerta })



    alerta.innerHTML = `<button class="btnAlert" onclick="document.getElementById('alertSystem').style.top = '-100%'">X</button> ${data.mensaje}`
    alerta.style.top = '10px'


}

export function render(data) {
    if (data.contenido) {
        if (data.modo === undefined) {
            document.querySelector(data.parent || '#appContent').innerHTML = data.contenido
        } else {
            document.querySelector(data.parent || '#appContent').append(data.contenido)
        }
    } else {
        alert({ 'tipo': 0, 'mensaje': 'Ha ocurrido un error' })
    }


}

export function ocultarModal() {
    let modal = ge('#modal')
    modal.classList.remove('showModal')
    setTimeout(() => {
        clearModal()
    }, 400)
    bgModal(false)
    ge('#modal-container').style.display = 'none'

}

export function modalBack() {
    modalStatus--
    if (modalHistory[modalStatus].length === 0) {
        ocultarModal()
    } else {
        ge('#contentModal').innerHTML = modalHistory[modalStatus]
    }
}
export function clearModal() {
    modalHistory = []
    modalStatus = 0
    ReactDOM.unmountComponentAtNode(ge('#contentModal'))

}

ReactDOM.render(
    <CloseOutline />,
    document.getElementById('closeModal')
)

export function modal(data) {
    let contenModal = ge('#contentModal'),
        modal = ge('#modal'),
        headerModal = ge('#headerModal')
    modalHistory.push(data)
    modalStatus++

    ReactDOM.render(
        data.contenido,
        contenModal
    )
    headerModal.innerHTML = data.header ?? ''

    if (!modal.classList.contains('showModal')) {
        bgModal()
        modal.classList.add('showModal')
        ge('#modal-container').style.display = 'flex'
    }

    resizeModal()
}

export function ge(element) {
    return document.querySelector(element) ?? false
}
export function geA(element) {
    return document.querySelectorAll(element)
}

ge('#contentModal').addEventListener('DOMSubtreeModified', resizeModal)
ge('#bgModal').addEventListener('click', ocultarModal)
ge('#closeModal').addEventListener('click', ocultarModal)
export function changeStyle(element, lista) {
    const e = ge(element)
    for (var key in lista) {
        e.style[key] = lista[key]

    }
}


export function ocultarAlert() {
    let a = document.getElementById('alertSystem')
    a.style.top = '-100%'
    setTimeout(() => {
        a.remove()
    }, 600)
}



export function resizeModal() {
    var modal = ge('#modal')
    let topModal = (window.innerHeight - modal.getBoundingClientRect().height) / 6,
        left = (window.innerWidth - modal.getBoundingClientRect().width) / 4

    if (topModal < 1) {
        topModal = '5%';
    }
    if (left < 30) {
        left = '1%'
    }
    changeStyle('#modal', {
        'left': left + 'px',
        'top': topModal + 'px'
    })

    scrollToElement('#top')



}


export function bgModal(show = true) {
    let bg = ge('#bgModal')
    if (show) {
        bg.style.display = 'block'
        bg.style.opacity = .8

    } else {
        bg.style.opacity = 0
        setTimeout(() => {
            bg.style.display = 'none'

        }, 600)
    }
}

export function scrollToElement(element) {
    element = ge(element).scrollIntoView({ behavior: 'smooth', block: 'start' })
}

export function logOut() {
    control({ 'api': 'publico', 'request': 'logOut' }).then(() => {

        localStorage.clear()
        sessionStorage.clear()
        window.location.href = '/'
    })

}



export function dropDown(e) {
    let menu = document.getElementById(e.attributes.name.value)
    if (menu.classList.contains('show')) {
        menu.classList.remove('show')
    } else {
        menu.classList.add('show')

    }
}

export function dropDownClose(e) {
    document.getElementById(e).classList.remove('show')
}

export function pageScroll(to, n = 40) {
    var element_to_scroll_to = document.querySelector(to);
    var height = element_to_scroll_to.getBoundingClientRect().top;
    var viewHeight = height - n;
    window.scroll(0, viewHeight)
}



export function convertToSlug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
    var to = "aaaaaeeeeeiiiiooooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    str = str.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');

    return str;
};

export function addTo(to, clas_s) {
    const selected = document.querySelector(to);
    selected.classList.toggle(clas_s)
};

export function lastPath() {
    const path = window.location.pathname.split("/");
    return (path[path.length - 1])
}

export function addClassIf(to, clas_s) {
    const selected = document.querySelector(to);
    if (selected) {
        if (!selected.classList.contains(clas_s)) {
            selected.classList.add(clas_s);
        }
    }
};

export function deleteClassIf(to, clas_s) {
    const selected = document.querySelector(to);
    if (selected.classList.contains(clas_s)) {
        addTo(to, clas_s)
    }
}

export function selectGrid(type, typeToRemove, where) {
    const typeSelected = document.querySelector(where);
    if (typeSelected.classList.contains(type)) {
        if (typeSelected.classList.contains(typeToRemove)) typeSelected.classList.remove(typeToRemove);
    } else {
        typeSelected.classList.add(type);
        if (typeSelected.classList.contains(typeToRemove)) typeSelected.classList.remove(typeToRemove);
    }
}

export function gridStyle(type) {
    ge('#gridStyle').href = `/style/${type}.css`
    setLocal({ item: 'gridStyle', value: type })
}




function readFileAsync(f, type = 'dataUrl') {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result)
        };


        reader.onerror = reject
        if (type === 'text') {
            reader.readAsText(f);

        } else if (type === 'arrayBuffer') {
            reader.readAsArrayBuffer(f);
        } else {
            reader.readAsDataURL(f);
        }
    })
}
export async function readFile(files, type = 'dataUrl') {
    if (files.length < 1) return false

    let list = []

    for (let i = 0; i < files.length; i++) {
        let f = files[i]
        list.push({
            title: f.name,
            type: f.type,
            content: await readFileAsync(f, type),
            extension: f.name.split('.').pop()
        })
    }

    return list
}


export function groupBy(list, key) {
    return list.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
    }, {})
}



export function createString(length = 10) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result
}