import React from 'react'
import ReactStars from 'react-rating-stars-component'
import { withRouter } from 'react-router'
import control, { alert, alertDanger, ge, geA, scrollToElement } from '../../../core/core'
import '../../../styles/formularioResena.css'


class Cuestionario extends React.Component {

    constructor() {
        super()
        this.state = {
            loading: true,
            status: true,
            cuestionarioEspecial: <></>,
            resena: {},
            resenaFinalizada: false,
            emailSuscripcion: '',
            idParticipante: 0,
            participanteValidado: false
        }
    }

    handleChange(e, v) {
        let resena = this.state.resena

        resena[e] = v

        ge(`#${e}`).value = v
        this.setState({ resena })

    }

    removeButtonSuccess(e) {
        let button = ge(`.${e}.btnSatisfaccionSuccess`)

        if (button) button.classList.remove('btnSatisfaccionSuccess')
    }

    addButtonSuccess(e) {
        e.target.classList.add('btnSatisfaccionSuccess')
    }

    async componentDidMount() {
        const { dniParticipante } = this.props.match.params



        await this.setState({ dniParticipante, loading: false })

        if (dniParticipante) this.validarParticipante(dniParticipante)
    }



    async validarParticipante(dniParticipante) {
        const validar = await control({ api: 'publico/resena', request: 'validarParticipante', data: dniParticipante })

        if (!validar.respuesta) return alertDanger('No se ha encontrado el participante')

        this.setState({ idParticipante: validar.respuesta })
    }



    async submitForm() {
        let inputs = geA('#formularioResenaContainer input'),
            status = true
        inputs.forEach(e => {
            if (e.required && e.value.length === 0) {
                status = false
                return alert({ tipo: 0, mensaje: 'Por favor completa el formulario' })
            }
        })

        if (status) {
            const r = await control({
                'api': 'publico/resena', 'request': 'nuevaResena', 'data': {
                    resena: this.state.resena,
                    idParticipante: this.state.idParticipante,
                    satisfaccionGlobal: this.state.resena.satisfaccionGlobal ?? 0
                }
            })


            if (!r.respuesta) return alert({ tipo: 0, mensaje: 'No hemos podido guardar tu reseña' })

            alert({ tipo: 1, mensaje: 'Gracias por tu reseña' })


            this.setState({ resenaFinalizada: true })

            scrollToElement('#top')
        }

    }




    render() {

        return (
            <div id="containerResena">

                {
                    this.state.loading ? (
                        <div className="spinner"></div>
                    ) : (
                        parseFloat(this.state.idParticipante) > 0 ? (
                            this.state.resenaFinalizada ? (
                                <div id="formularioResenaContainer" className="formularioResenaContainer">
                                    <h1>Gracias por tu reseña!!</h1>
                                </div>
                            ) : (
                                <div id="formularioResenaContainer" className="formularioResenaContainer">

                                    <div className="formularioResenaContainerTitulo">
                                        <h1>Déjanos tu comentario</h1>
                                    </div>
                                    <div className="formularioResenaSection">
                                        <div className="formularioResenaSection">
                                            <h2>Grado de satisfacción global del servicio prestado</h2>
                                        </div>
                                        <input type="hidden" id="satisfaccionGlobal" required />
                                        <ReactStars
                                            count={5}
                                            size={60}
                                            activeColor="#6aaa6e"
                                            onChange={v => this.handleChange('satisfaccionGlobal', v)}
                                        />
                                    </div>

                                    <>
                                        <div className="formularioResenaSection">
                                            <div className="formularioResenaSection">
                                                <h2>¿Ha recibido un trato amable y respetuoso?*</h2>
                                            </div>
                                            <input type="hidden" id="tratoAmableYRespetuoso" required />
                                            <ReactStars
                                                count={5}
                                                size={60}
                                                activeColor="#6aaa6e"
                                                onChange={v => this.handleChange('tratoAmableYRespetuoso', v)}
                                            />
                                        </div>



                                        <div className="formularioResenaSection">
                                            <div className="formularioResenaSection">
                                                <h2>¿La información facilitada sobre sus visitas, alojamiento y las actividades desarrolladas ha sido adecuada a sus necesidades?*</h2>
                                            </div>
                                            <input type="hidden" id="informacionSolicitada" required />
                                            <ReactStars
                                                count={5}
                                                size={60}
                                                activeColor="#6aaa6e"
                                                onChange={v => this.handleChange('informacionSolicitada', v)}
                                            />
                                        </div>




                                        <div className="formularioResenaSection">
                                            <div className="formularioResenaSection">
                                                <h2>¿La exposición por parte de los guías fue de su interés y se hizo con profesionalidad?*</h2>
                                            </div>
                                            <input type="hidden" id="estadoExposicion" required />
                                            <ReactStars
                                                count={5}
                                                size={60}
                                                activeColor="#6aaa6e"
                                                onChange={v => this.handleChange('estadoExposicion', v)}
                                            />
                                        </div>


                                        <div className="formularioResenaSection">
                                            <div className="formularioResenaSection">
                                                <h2>¿El material informativo disponible de la empresa que ha prestado el servicio y actividades le han parecido útiles e interesantes?*</h2>
                                            </div>
                                            <input type="hidden" id="materialInformativo" required />
                                            <ReactStars
                                                count={5}
                                                size={60}
                                                activeColor="#6aaa6e"
                                                onChange={v => this.handleChange('materialInformativo', v)}
                                            />
                                        </div>




                                        <div className="formularioResenaSection">
                                            <div className="formularioResenaSection">
                                                <h2>¿Han sido los autobuses cómodos y han sido puntuales?*</h2>
                                            </div>
                                            <input type="hidden" id="estadoDelAutobus" required />
                                            <button type="button" className="btnSatisfaccion estadoDelAutobus" onClick={e => {
                                                this.handleChange('estadoDelAutobus', 1)
                                                this.removeButtonSuccess('estadoDelAutobus')
                                                this.addButtonSuccess(e)
                                            }}>Si</button>
                                            <button type="button" className="btnSatisfaccion estadoDelAutobus" onClick={e => {
                                                this.handleChange('estadoDelAutobus', 0)
                                                this.removeButtonSuccess('estadoDelAutobus')
                                                this.addButtonSuccess(e)
                                            }}>No</button>
                                        </div>




                                        <div className="formularioResenaSection">
                                            <div className="formularioResenaSection">
                                                <h2>¿La accesibilidad de las instalaciones y la visita por el interior ha sido correcta?*</h2>
                                            </div>
                                            <input type="hidden" id="accesibilidadInstalaciones" required />
                                            <ReactStars
                                                count={5}
                                                size={60}
                                                activeColor="#6aaa6e"
                                                onChange={v => this.handleChange('accesibilidadInstalaciones', v)}
                                            />
                                        </div>



                                        <div className="formularioResenaSection">
                                            <div className="formularioResenaSection">

                                                <h2>¿Los monumentos visitados han sido de su interés?*</h2>
                                            </div>
                                            <input type="hidden" id="monumentosDeInteres" required />
                                            <button type="button" className="btnSatisfaccion monumentosDeInteres" onClick={e => {
                                                this.handleChange('monumentosDeInteres', 1)
                                                this.removeButtonSuccess('monumentosDeInteres')
                                                this.addButtonSuccess(e)
                                            }}>Si</button>
                                            <button type="button" className="btnSatisfaccion monumentosDeInteres" onClick={e => {
                                                this.handleChange('monumentosDeInteres', 0)
                                                this.removeButtonSuccess('monumentosDeInteres')
                                                this.addButtonSuccess(e)
                                            }}>No</button>
                                        </div>






                                        <div className="formularioResenaSection">
                                            <div className="formularioResenaSection">

                                                <h2>¿Has disfrutado de los juegos nocturnos?*</h2>
                                            </div>
                                            <input type="hidden" id="disfrutadoJuegosNocturnos" required />
                                            <button type="button" className="btnSatisfaccion disfrutadoJuegosNocturnos" onClick={e => {
                                                this.handleChange('disfrutadoJuegosNocturnos', 1)
                                                this.removeButtonSuccess('disfrutadoJuegosNocturnos')
                                                this.addButtonSuccess(e)
                                            }}>Si</button>
                                            <button type="button" className="btnSatisfaccion disfrutadoJuegosNocturnos" onClick={e => {
                                                this.handleChange('disfrutadoJuegosNocturnos', 0)
                                                this.removeButtonSuccess('disfrutadoJuegosNocturnos')
                                                this.addButtonSuccess(e)
                                            }}>No</button>
                                        </div>


                                    </>



                                    <div className="formularioResenaSection">
                                        <div className="formularioResenaSection">
                                            <h2>¿En qué mejoraría  el servicio prestado?</h2>
                                            <textarea className="hidden" id="comentario" ></textarea>
                                            <textarea id="textareaComentario" placeholder="Ingresa un comentario" onChange={e => this.handleChange('comentario', e.target.value)}></textarea>
                                        </div>
                                    </div>




                                    <div className="formularioResenaSection">
                                        <button type="button" className="btnForm success" onClick={() => this.submitForm()}>
                                            Enviar reseña
                                        </button>
                                    </div>


                                </div>
                            )


                        ) : (
                            <>
                                <div className="validarParticipanteContainer loginContainer" style={{marginTop:'60px'}}>

                                    <div className="validarParticipante">
                                        <div className="inputSection wrap">
                                            <label htmlFor="dni">Introduce tu DNI</label>
                                            <input type="text" id="dniParticipante" placeholder="Introduce tu DNI" />
                                        </div>

                                        <div className="inputSection wrap">
                                            <button type="button" className="success w100" onClick={() => {

                                                const idParticipante = ge('#dniParticipante').value
                                                this.validarParticipante(idParticipante)
                                            }}>
                                                Validar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    )
                }
            </div>
        )
    }
}

export default withRouter(Cuestionario)