import React from 'react'
import { Link } from 'react-router-dom'
import headerSection from '../../../components/headerSection'
import RoundSpinner from '../../../components/roundSpinner/roundSpinner'
import Table from '../../../components/table/table'
import control from '../../../core/core'
import contratos_trabajadores from '../../../components/storage/contratos/contratosTrabajadores'
import { GrEdit } from 'react-icons/gr'
import lista_proveedores from '../../../components/storage/proveedores/proveedores'


export default class Trabajadores extends React.Component {


    state = {
        loading: true,
        trabajadores: []
    }

    async componentDidMount() {
        this.listarTrabajadores()
    }

    async listarTrabajadores() {
        this.setState({ loading: true })
        const trabajadores = await control({ api: 'trabajadores/trabajadores', request: 'listarTrabajadores' })
        if (trabajadores.respuesta) {
            this.setState({ trabajadores: trabajadores.respuesta })

        } else {
            this.setState({ trabajadores: [] })
        }

        this.setState({
            contratos: await contratos_trabajadores(),
            proveedores: await lista_proveedores(),
            loading: false
        })

    }







    




    render() {

        return (<>
            {
                headerSection('Trabajadores')
            }
            <div className="appElement">
                <Link to="/trabajador/0">
                    <button type="button" className="outlineSuccess btnSm">+ Nuevo</button>
                </Link>
                <Link to="/idiomas">
                    <button type="button" className="outlineSuccess btnSm">Idiomas</button>
                </Link>
            </div>

            <div className="appElement">

                {
                    Table({
                        id: 'trabajadores',
                        thead: [{
                            'title': ''
                        }, {
                            'title': 'Nombre'
                        },
                        {
                            'title': 'Teléfono'
                        }, {
                            'title': 'Email'
                        }
                        ],
                        tbody: this.state.loading ? (
                            <RoundSpinner />
                        ) : (
                            this.state.trabajadores && this.state.trabajadores.length > 0 ? (
                                this.state.trabajadores.map(e => {
                                    

                                    return (
                                        <tr>
                                            <td>
                                                <Link to={`/trabajador/${e.idTrabajador}`}>
                                                    <button type="button" className="outlineSuccess">
                                                        <GrEdit />
                                                    </button>
                                                </Link>
                                            </td>
                                            <td>{e.nombre}</td>
                                            <td>{e.telefono}</td>
                                            <td>{e.mail}</td>
                                        </tr>
                                    )
                                })


                            ) : (
                                <tr>
                                    <td>No se encontraron trabajadores</td>
                                </tr>
                            )
                        )
                    })
                }
            </div>

        </>

        )
    }

}