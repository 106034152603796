import { geA } from "../../core/core"

export default function tableToExcel(d) {
    let downloadLink,
        dataType = 'application/vnd.ms-excel',
        tableSelect = document.getElementById(d.idTable),
        tableHTML = tableSelect.outerHTML.replace(/ /g, '%20'),
        fileName = d.fileName ? d.fileName + '.xls' : 'excel_data.xls'

    downloadLink = document.createElement("a")

    document.body.appendChild(downloadLink)

    if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(['\ufeff', tableHTML], {
            type: dataType
        })
        navigator.msSaveOrOpenBlob(blob, fileName)
    } else {
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML

        downloadLink.download = fileName
        downloadLink.click()
    }

    downloadLink.remove()
}