import React from 'react'
import RoundSpinner from '../../../components/roundSpinner/roundSpinner'
import control, { alert, form } from '../../../core/core'



import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';



export default class EditarItinerario extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            idItinerario: this.props.idItinerario,
            loading: true,
            itinerario: {},
            editorState: EditorState.createEmpty(),
        }
        this.handleInputChange = this.handleInputChange.bind(this)

    }


    handleInputChange(event) {
        const target = event.target
        let value = target.value

        if (target.type === 'checkbox') value = target.checked


        this.setState(prevState => ({
            ...prevState,
            itinerario: {
                ...prevState.itinerario,
                [target.id]: value
            }
        }))
    }

    onEditorStateChange(editorState) {

        this.setState({
            editorState,
        });

    }

    async componentDidMount() {
        if (parseFloat(this.state.idItinerario) > 0) {
            let itinerario = await control({ api: 'itinerarios/itinerarios', request: 'detallesItinerarioAdmin',data:this.state.idItinerario })

            if (!itinerario.respuesta) return window.location = '/itinerarios'

            itinerario = itinerario.respuesta

            this.setState({ itinerario })


            let contentBlocks = convertFromHTML(itinerario.descripcion)
            contentBlocks = ContentState.createFromBlockArray(
                contentBlocks.contentBlocks,
                contentBlocks.entityMap
            );
            await this.setState({ editorState: EditorState.createWithContent(contentBlocks) })

        }



        let recursos = await control({ api: 'itinerarios/itinerarios', request: 'recursosEditarItinerario' })

        
        if (!recursos.respuesta) return alert({ tipo: 0, mensaje: 'No se han podido cargar todos los recursos' })
        recursos = recursos.respuesta


        this.setState({ recursos, loading: false })
    }

    async procesarItinerario(e) {
        e.preventDefault()

        const process = await form({ form: 'procesarItinerario', api: 'itinerarios/itinerarios' })

        if(!process.respuesta.success) return alert({tipo:0,mensaje:'No pudo guardar los cambios. Por favor, comprueba los campos'})


        if(!process.respuesta.nuevo) return alert({tipo:1,mensaje:'Itinerario actualizado'})
        
        this.setState({idItinerario:process.idItinerario})
        alert({tipo:1,mensaje:'Itinerario creado correctamente'})
   
    }
    render() {
        return (

            this.state.loading ? (
                <RoundSpinner />
            ) : (
                <form id="procesarItinerario" onSubmit={e => this.procesarItinerario(e)}>

                    <input type="hidden" id="idItinerario" value={this.state.idItinerario}/>
                    <div className="grid1">
                        <div className="appElement">

                            <div className="appElementHeader">
                                {
                                    this.state.idItinerario > 0 ? (
                                        `Editar el itinerario ${this.state.itinerario.titulo}`
                                    ) : (
                                        'Crear nuevo itinerario'
                                    )
                                }
                            </div>

                            <div className="appElementContent">
                                <div className="grid2">
                                    <div className="inputSection wrap">
                                        <label htmlFor="titulo">
                                            Titulo del itinerario
                                        </label>
                                        <input type="text" id="titulo" placeholder="Introduce el título descriptivo del itinerario" value={this.state.itinerario.titulo} onChange={this.handleInputChange} />
                                    </div>
                                    <div className="grid2">
                                        <div className="inputSection wrap">
                                            <label htmlFor="titulo">
                                                Fecha salida
                                            </label>
                                            <input type="date" id="fechaInicio" placeholder="Selecciona la fecha de salida" value={this.state.itinerario.fechaInicio} onChange={this.handleInputChange} />
                                        </div>
                                        <div className="inputSection wrap">
                                            <label htmlFor="titulo">
                                                Fecha llegada
                                            </label>
                                            <input type="date" id="fechaFin" placeholder="Selecciona la fecha de llegada" value={this.state.itinerario.fechaFin} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="appElement">
                            <div className="appElementHeader">
                                Detalles del itinerario
                            </div>

                            <div className="appElementContent">
                                <div className="inputSection wrap">

                                    <Editor
                                        editorState={this.state.editorState}
                                        editorClassName="editorQwerty"
                                        placeholder="Describe aquí la actividad"
                                        onEditorStateChange={e => this.onEditorStateChange(e)}
                                    />
                                    <textarea className="hidden" id="descripcion" onChange={this.handleInputChange} value={draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))} />

                                </div>
                            </div>
                        </div>


                        <div className="appElement">

                            <div className="appElementHeader">
                                Transporte y trabajadores
                            </div>

                            <div className="appElementContent">
                                <div className="grid4">
                                    <div className="inputSection wrap">
                                        <label htmlFor="conductor">
                                            Selecciona un conductor
                                        </label>
                                        <select value={this.state.itinerario.conductor} id='conductor' onChange={this.handleInputChange}>
                                            {
                                                this.state.recursos.trabajadores ? this.state.recursos.trabajadores.map(e => {
                                                    return <option value={e.idTrabajador}>{e.nombre}</option>
                                                }) : null
                                            }
                                        </select>
                                    </div>

                                    <div className="inputSection wrap">
                                        <label htmlFor="guia1">
                                            Guía 1
                                        </label>
                                        <select value={this.state.itinerario.guia1} id='guia1' onChange={this.handleInputChange}>
                                            {
                                                this.state.recursos.trabajadores ? this.state.recursos.trabajadores.map(e => {
                                                    return <option value={e.idTrabajador}>{e.nombre}</option>
                                                }) : null
                                            }
                                        </select>


                                    </div>


                                    <div className="inputSection wrap">
                                        <label htmlFor="guia2">
                                            Guía 2
                                        </label>
                                        <select value={this.state.itinerario.guia2} id='guia2' onChange={this.handleInputChange}>
                                            {
                                                this.state.recursos.trabajadores ? this.state.recursos.trabajadores.map(e => {
                                                    return <option value={e.idTrabajador}>{e.nombre}</option>
                                                }) : null
                                            }
                                        </select>
                                    </div>


                                    <div className="inputSection wrap">
                                        <label htmlFor="guia2">
                                            Vehículo asignado
                                        </label>
                                        <select value={this.state.itinerario.vehiculo} id='vehiculo' onChange={this.handleInputChange}>
                                            {
                                                this.state.recursos.vehiculos ? this.state.recursos.vehiculos.map(e => {
                                                    return <option value={e.idVehiculo}>{e.marca} - {e.matricula}</option>
                                                }) : null
                                            }
                                        </select>
                                    </div>



                                </div>
                            </div>
                        </div>


                        <div className="appElement">
                            <div className="inputSection wrap">
                                <button type="submit" className="success">Guardar</button>
                            </div>
                        </div>






                    </div>

                </form>
            )
        )
    }
}