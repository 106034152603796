import React from 'react'
import RoundSpinner from '../../../components/roundSpinner/roundSpinner'
import control, { alert, alertDanger, alertSuccess, form, local, modal, ocultarModal } from '../../../core/core'
import Table from '../../../components/table/table'
import { QrCode } from 'react-ionicons'
import { GrEdit } from 'react-icons/gr'
import formularioParticipante from '../participantes/formularioParticipante'
import QRCode from 'qrcode.react'
import pdfPrint from '../../../components/printToPdf'
import { AiOutlineFilePdf } from 'react-icons/ai'



export default class EditarItinerario extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            idItinerario: this.props.idItinerario,
            loading: true,
            itinerario: {},
            eventos: [],
            participantes: [],
            filtroParticipantes: '',
            tipoEvento: [],
            _t: parseFloat(local('_t')),
            encuestas: [],
            terminaBusquedaEncuesta: ''
        }



    }


    async componentDidMount() {
        if (parseFloat(this.state.idItinerario) < 1) return window.location = '/itinerarios'

        let itinerario = await control({ api: 'itinerarios/itinerarios', request: 'detallesItinerarioAdmin', data: this.state.idItinerario })

        if (!itinerario.respuesta) return window.location = '/itinerarios'

        itinerario = itinerario.respuesta



        const participantes = await control({ request: 'filtrarParticipantes', api: 'participantes/participantes', data: { idItinerario: this.state.idItinerario } })
        const eventos = await control({ request: 'listarEventosItinerario', api: 'itinerarios/itinerarios', data: this.state.idItinerario })

        this.setState({ participantes: participantes.respuesta ?? [], itinerario, eventos: eventos.respuesta ?? [] })


        let recursos = await control({ api: 'itinerarios/itinerarios', request: 'recursosEditarItinerario' })


        if (!recursos.respuesta) return alert({ tipo: 0, mensaje: 'No se han podido cargar todos los recursos' })
        recursos = recursos.respuesta


        this.setState({ recursos, loading: false })


    }








    async procesarNuevoEvento(e) {
        e.preventDefault()

        const nuevoEvento = await form({ form: 'nuevoEvento', api: 'itinerarios/itinerarios' })

        if (!nuevoEvento.respuesta) return alertDanger('Ha ocurrido un error al guardar el evento')

        alertSuccess('Evento creado correctamente')
        ocultarModal()


        this.setState(prevState => ({
            eventos: [...prevState.eventos, nuevoEvento.respuesta]
        }))

    }


    async formularioNuevoEvento() {

        if (this.state.tipoEvento.length === 0) {
            const tipoEvento = await control({ api: 'itinerarios/itinerarios', request: 'tipoEvento' })
            if (tipoEvento.respuesta) await this.setState({ tipoEvento: tipoEvento.respuesta })
        }


        return (
            <>
                <form id="nuevoEvento" onSubmit={e => this.procesarNuevoEvento(e)}>
                    <input type="hidden" value={this.state.idItinerario} id="idItinerario" />
                    <div className="inputSection wrap">
                        <label htmlFor="idTipoEvento">Tipo de evento</label>
                        <select id="idTipoEvento" required>
                            {this.state.tipoEvento.map(e => {
                                return <option value={e.idTipoEvento}>{e.titulo}</option>
                            })}
                        </select>
                    </div>

                    <div className="inputSection wrap">
                        <label htmlFor="comentario">Comentario</label>
                        <textarea id="comentario" placeholder="Ingresa un comentario opcional" />
                    </div>
                    <div className="inputSection wrap">
                        <button type="submit" className="success">
                            Guardar evento
                        </button>
                    </div>
                </form>
            </>
        )
    }


    async nuevoEvento() {
        modal({
            header: 'Nuevo evento',
            contenido: await this.formularioNuevoEvento()
        })
    }





    render() {

        let participantes = this.state.participantes ?? []
        if (this.state.filtroParticipantes.length > 3) {
            const terminoBusqueda = this.state.filtroParticipantes.toLowerCase()
            participantes = participantes.filter(e => e.nombre.toLowerCase().indexOf(terminoBusqueda) !== -1)
        }


        let encuestas = this.state.itinerario.encuestas ?? []
        if (this.state.terminoBusquedaEncuesta && this.state.terminoBusquedaEncuesta.length > 3) {
            const terminaBusquedaEncuesta = this.state.terminaBusquedaEncuesta.toLowerCase()
            console.log('filter')
            encuestas = encuestas.filter(e => e.nombre.toLowerCase().indexOf(terminaBusquedaEncuesta) !== -1)
        }



        return (

            this.state.loading ? (
                <RoundSpinner />
            ) : (
                <>


                    <div className="appElement">
                        <button type="button" className="danger" onClick={() => contacto()}>
                            Información de contacto
                        </button>


                        <button type="button" className="outlineDanger" onClick={() => pdfPrint({
                            idDiv: 'Itinerario'
                        })}>
                            <AiOutlineFilePdf /> Exportar en PDF
                        </button>
                        <button type="button" className="success" onClick={() => showQrQuizz(this.state.idItinerario)}>
                            Jugar Quizz
                        </button>
                        {
                            this.state._t === 3 ? (
                                <>
                                    <button type="button" className="outlineSuccess" onClick={() => this.nuevoEvento()}>
                                        + Nuevo evento
                                    </button>

                                    <button type="button" className="outlineInfo" onClick={() => showQrCuestionario()}>
                                        Cuestionario
                                    </button>
                                </>

                            ) : (
                                null
                            )
                        }

                    </div>

                    <div id="Itinerario">
                        <div className="grid1">
                            <div className="appElement">

                                <div className="appElementHeader">
                                    Detalles del itinerario
                                </div>

                                <div className="appElementContent">
                                    <div className="grid1">


                                        <div className="grid2">
                                            <div className="inputSection wrap">
                                                {this.state.itinerario.titulo}
                                            </div>
                                            <div className="grid2">
                                                <div className="inputSection">
                                                    <strong>Salida </strong><br></br>
                                                    <span>{this.state.itinerario.fechaInicio}</span>
                                                </div>
                                                <div className="inputSection">
                                                    <strong>Llegada </strong><br></br>
                                                    <span>{this.state.itinerario.fechaFin}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="grid1">
                                            <div dangerouslySetInnerHTML={{ __html: this.state.itinerario.descripcion }}>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="appElement">

                                <div className="appElementHeader">
                                    Transporte y trabajadores
                                </div>

                                <div className="appElementContent">
                                    <div className="grid2">


                                        <div className="inputSection wrap">
                                            <label htmlFor="guia2">
                                                Vehículo asignado
                                            </label>
                                            <select disabled value={this.state.itinerario.vehiculo} id='vehiculo' onChange={this.handleInputChange}>
                                                {
                                                    this.state.recursos.vehiculos ? this.state.recursos.vehiculos.map(e => {
                                                        return <option value={e.idVehiculo}>{e.marca} - {e.matricula}</option>
                                                    }) : null
                                                }
                                            </select>
                                        </div>


                                        <div className="inputSection wrap">
                                            <label htmlFor="conductor">
                                                Selecciona un conductor
                                            </label>
                                            <select disabled value={this.state.itinerario.conductor} id='conductor' onChange={this.handleInputChange}>
                                                {
                                                    this.state.recursos.trabajadores ? this.state.recursos.trabajadores.map(e => {
                                                        return <option value={e.idTrabajador}>{e.nombre}</option>
                                                    }) : null
                                                }
                                            </select>
                                        </div>

                                        <div className="inputSection wrap">
                                            <label htmlFor="guia1">
                                                Guía 1
                                            </label>
                                            <select disabled value={this.state.itinerario.guia1} id='guia1' onChange={this.handleInputChange}>
                                                {
                                                    this.state.recursos.trabajadores ? this.state.recursos.trabajadores.map(e => {
                                                        return <option value={e.idTrabajador}>{e.nombre}</option>
                                                    }) : null
                                                }
                                            </select>
                                        </div>


                                        <div className="inputSection wrap">
                                            <label htmlFor="guia2">
                                                Guía 2
                                            </label>
                                            <select disabled value={this.state.itinerario.guia2} id='guia2' onChange={this.handleInputChange}>
                                                {
                                                    this.state.recursos.trabajadores ? this.state.recursos.trabajadores.map(e => {
                                                        return <option value={e.idTrabajador}>{e.nombre}</option>
                                                    }) : null
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="grid2">

                                <div className="appElement">
                                    <div className="appElementHeader">
                                        Eventos reportados
                                    </div>
                                    <div className="appElementContent" style={{ maxHeight: '600px' }}>
                                        {
                                            Table({
                                                id: 'Eventos',
                                                thead: [{
                                                    title: 'Evento'
                                                }, {
                                                    title: 'Usuario'
                                                }, {
                                                    title: 'Fecha'
                                                }
                                                ],
                                                tbody: this.state.eventos.length > 0 ? (
                                                    this.state.eventos.sort((a, b) => {
                                                        if (a.fecha > b.fecha) return -1
                                                        return 1
                                                    }).map(e => {
                                                        return (
                                                            <tr>
                                                                <td>{e.evento} {e.comentario !== null && e.comentario !== '' ? ' - ' + e.comentario : ''}</td>
                                                                <td>{e.usuario}</td>
                                                                <td>{e.fecha}</td>
                                                            </tr>
                                                        )
                                                    })
                                                ) : (
                                                    <tr><td>No se encontraron eventos</td></tr>
                                                )
                                            })
                                        }

                                    </div>
                                </div>




                                <div className="appElement">
                                    <div className="appElementHeader">
                                        Participantes
                                    </div>

                                    <div className="appElementContent maxH600">
                                        <div className="inputSection">
                                            <input type="text" placeholder="Buscar participante" onChange={e => this.setState({ filtroParticipantes: e.target.value })} />

                                        </div>
                                        {
                                            Table({
                                                id: 'Participantes',
                                                thead: [{
                                                    title: 'Nombre'
                                                }, {
                                                    title: 'Teléfono'
                                                }, {
                                                    title: ''
                                                }
                                                ],
                                                tbody: participantes.length > 0 ? (
                                                    participantes.map(e => {

                                                        console.log(e)
                                                        return (
                                                            <tr>
                                                                <td>{e.nombre}</td>
                                                                <td>{e.telefono}</td>



                                                                <td>
                                                                    {
                                                                        this.state._t !== 2 ? (
                                                                            <button type="button" className="success" onClick={() => formularioParticipante({ idParticipante: e.idParticipante, nombre: e.nombre })}>
                                                                                <GrEdit />
                                                                            </button>

                                                                        ) : (
                                                                            null
                                                                        )
                                                                    }
                                                                    <button type="button" className="success" onClick={() => showQrCuestionario(e.dni, e.nombre)}>
                                                                        <QrCode />
                                                                    </button>
                                                                </td>

                                                            </tr>
                                                        )
                                                    })
                                                ) : (
                                                    <tr><td>No se encontraron participantes</td></tr>
                                                )
                                            })
                                        }

                                    </div>
                                </div>






                            </div>

                            <div className="appElement">
                                <div className="appElementHeader">
                                    Encuestas
                                </div>

                                <div className="appElementContent maxH600">
                                    {/* <div className="grid3">
                                        <div className="inputSection">
                                            <input type="text" id="terminoBusquedaEncuesta" placeholder="Buscar participante" onChange={e => this.setState({ terminoBusquedaEncuesta: e.target.value })} />

                                        </div>

                                    </div> */}
                                    {
                                        Table({
                                            id: 'Encuestas',
                                            thead: [{
                                                title: 'Participante'
                                            }, {
                                                title: 'Valoración general'
                                            }, {
                                                title: 'Valoración trato recibido'
                                            }, {
                                                title: 'Valoración informacion'
                                            }, {
                                                title: 'Valoración exposiciones'
                                            }, {
                                                title: 'Valoración material informativo'
                                            }, {
                                                title: 'Valoración autobuses'
                                            }, {
                                                title: 'Valoración accesibilidad'
                                            }, {
                                                title: 'Valoración monumentos'
                                            }, {
                                                title: '¿Ha disfrutado los juegos nocturnos?'
                                            }, {
                                                title: '¿En qué mejoraría  el servicio prestado?'
                                            }
                                            ],
                                            tbody: encuestas.length > 0 ? (
                                                encuestas.map(e => {

                                                    try {
                                                        e.resena = JSON.parse(e.resena)
                                                    } catch (err) { }

                                                    return (
                                                        <tr>
                                                            <td>{e.nombre}</td>
                                                            <td>{

                                                                e.resena.satisfaccionGlobal
                                                            }</td>
                                                            <td>{

                                                                e.resena.tratoAmableYRespetuoso
                                                            }</td>
                                                            <td>{

                                                                e.resena.informacionSolicitada
                                                            }</td>
                                                            <td>{

                                                                e.resena.estadoExposicion
                                                            }</td>
                                                            <td>{

                                                                e.resena.materialInformativo
                                                            }</td>
                                                            <td>{
                                                                parseFloat(e.resena.estadoDelAutobus) === 1 ? 'Si' : 'No'

                                                            }</td>
                                                            <td>{

                                                                e.resena.accesibilidadInstalaciones
                                                            }</td>
                                                            <td>{
                                                                parseFloat(e.resena.monumentosDeInteres) === 1 ? 'Si' : 'No'

                                                            }</td>
                                                            <td>{

                                                                parseFloat(e.resena.disfrutadoJuegosNocturnos) === 1 ? 'Si' : 'No'

                                                            }</td>

                                                            <td>
                                                                {
                                                                    e.resena.comentario
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            ) : (
                                                <tr><td>No se encontraron encuestas</td></tr>
                                            )
                                        })
                                    }

                                </div>
                            </div>

                        </div>

                    </div>


                </>
            )
        )
    }
}


export function contacto() {
    modal({
        header: 'Teléfonos de contacto',
        contenido: <div className="center">
            <h2>Teléfonos en horario comercial:</h2>
            <hr></hr>
            <p>
                <a href="tel:953 49 60 27">- 953 49 60 27 (OFICINA GIL SAN)</a>
                <br></br>
                <a href="tel:627 628 080">- 627 628 080 (NATTULE).</a>
                <br></br>
            </p>
            <h2>Teléfonos de emergencia 24 HORAS..</h2>
            <hr></hr>
            <p>

                <a href="tel:629 361 601">- 629 361 601 (RAÚL)</a>
                <br></br>
                <a href="tel:636 679 081">- 636 679 081 (ADRIANO)</a>

            </p>
        </div>
    })

}

const qrImageSettings = {
    src: '/logos/nattule.jpg',
    height: 60,
    width: 60
}

export function showQrQuizz(idItinerario) {

    modal({
        header: 'Quizz',
        contenido: <div style={{ textAlign: 'center' }}>
            <p>Escanea el siguiente QR para jugar</p>
            <div className="qrCodeResena">
                <QRCode
                    imageSettings={qrImageSettings}
                    value={'https://senior.nattule.com/quizz/' + idItinerario}
                    size={320} />
            </div>
            <hr></hr>
            <a href={'https://senior.nattule.com/quizz/' + idItinerario} target="_blank" rel="noreferrer">
                <button type="button" className="success" >
                    Ir al juego
                </button>
            </a>
        </div>
    })
}


export function showQrCuestionario(dniParticipante = false, nombreParticipante = false) {

    const url = dniParticipante ? 'https://senior.nattule.com/cuestionario/' + dniParticipante : 'https://senior.nattule.com/cuestionario'
    modal({
        header: nombreParticipante ? 'Reseña de ' + nombreParticipante : 'Formulario de reseña',
        contenido: <div style={{ textAlign: 'center' }}>
            <p>Escanea el siguiente QR para realiazar la encuesta</p>
            <div className="qrCodeResena">
                <QRCode 
                    imageSettings={qrImageSettings}
                    value={url}
                    size={320} />
            </div>
            <hr></hr>
            <a href={url} target="_blank" rel="noreferrer">
                <button type="button" className="success" >
                    Ir a la encuesta
                </button>
            </a>
        </div>
    })
}

