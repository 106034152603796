import React from 'react'
import control, { alertDanger, alertSuccess, form, modal, ocultarModal, setLocal } from '../../../core/core'
import RoundSpinner from '../../../components/roundSpinner/roundSpinner'
import Table from '../../../components/table/table'
import headerSection from '../../../components/headerSection'

import { GrEdit } from "react-icons/gr";
import lista_idiomas from '../../../components/storage/idiomas/idiomas'


class Idiomas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            idiomas: []
        }
    }


    async componentDidMount() {
        setLocal({ item: 'idiomas', value: false })

        this.setState({ idiomas: await lista_idiomas() })

        this.setState({ loading: false })
    }



    setInLocal(){
        setLocal({item:'idiomas',value:this.state.idiomas})
    }


    async eliminaridioma(idIdioma){
        const eliminar = await control({api:'idiomas/idiomas',request:'eliminarIdioma',data:idIdioma})

        if(eliminar.respuesta){

            const idiomas = this.state.idiomas.filter(e => parseFloat(e.idIdioma) !== parseFloat(idIdioma))

            await this.setState(prevState => ({
                ...prevState,
                idiomas: idiomas
            }))


            this.setInLocal()

            ocultarModal()
            alertSuccess('Idioma eliminado')
        }else{
            alertDanger('No se pudo eliminar el idioma')
        }
    }

    async procesarFormulario(e) {
        e.preventDefault()

        let editarIdioma = await form({ api: 'idiomas/idiomas', form: 'updateIdioma' })
        editarIdioma = editarIdioma.respuesta

        if (editarIdioma.status) {

            if(editarIdioma.nuevoIdioma){


                await this.setState(prevState => ({
                    ...prevState,
                    idiomas: [...prevState.idiomas, editarIdioma.detallesIdioma]
                }))

            alertSuccess('Idioma creado correctamente')
        
        
        }else{

                let idiomas = this.state.idiomas
                const index = idiomas.findIndex(e => parseFloat(e.idIdioma) === parseFloat(editarIdioma.detallesIdioma.idIdioma))

            if (~index) idiomas[index] = editarIdioma.detallesIdioma

            await this.setState(prevState => ({
                ...prevState,
                idiomas: idiomas
            }))


            alertSuccess('Idioma editado correctamente')



            }

            this.setInLocal()
            ocultarModal()
            
        } else {
            alertDanger('Ha ocurrido un error al guardar los cambios')
        }
    }


    confirmarEliminaridioma(idioma){


        return modal({
            header:'Eliminiar idioma',
            contenido: <>
                <h2>¿ Quieres eliminar el idioma {idioma.titulo} ?</h2>
                <button type="button" className="outlineDanger" onClick={() => this.eliminaridioma(idioma.idIdioma)}>Si eliminar</button>
                <button type="button" className="success" onClick={() => ocultarModal()}>No eliminar</button>
            </>
        })
    }
    formularioIdioma(d = {
        idIdioma: 0,
        titulo: ''
    }) {
        return (
            <>
                <form id="updateIdioma" onSubmit={e => this.procesarFormulario(e)}>

                <input type="hidden" id="idIdioma" value={d.idIdioma} />

                    <div className="inputSection wrap">
                        <label>Nombre de la idioma</label>
                        <input type="text" id="titulo" placeholder="Ingresa el nombre de la idioma" defaultValue={d.titulo} required />
                    </div>
                    <div className="inputSection">
                        <button type="submit" className="success">
                            Guardar
                        </button>
                        <button onClick={() => ocultarModal()} className="outlineDanger">
                            Cancelar
                        </button>
                        {
                            d.idIdioma > 0 ? (
                                <>
                                    <button onClick={() => this.confirmarEliminaridioma(d)}>
                                        Eliminar
                                    </button>
                                </>
                            ) : (
                                null
                            )
                        }
                    </div>
                </form>
            </>
        )
    }
    editarIdioma(idioma){
        return modal(
            {
                header: 'Editar idioma',
                contenido: this.formularioIdioma(idioma)
            }
        )
    }
    nuevoIdioma() {
        return modal(
            {
                header: 'Nuevo idioma',
                contenido: this.formularioIdioma({ idIdioma: 0 })
            }
        )
    }
    render() {

        return (
            <>
                {
                    headerSection('idiomas')
                }

                <div className="appElement">
                    <button type="button" className="outlineSuccess" onClick={() => this.nuevoIdioma()}>
                        + Nuevo idioma
                    </button>
                    {
                        Table({
                            id: 'idiomas',
                            thead: [{
                                'title': 'Titulo'
                            }, {
                                'title': ' '
                            }
                            ],
                            tbody: this.state.loading ? (
                                <RoundSpinner />
                            ) : (
                                this.state.idiomas.length > 0 ? (
                                    this.state.idiomas.map(e => {
                                        return (
                                            <tr>

                                                <td>
                                                    {e.titulo}
                                                </td>
                                                <td>
                                                    <button type="button" className="outlineSuccess" onClick={() => this.editarIdioma(e)}>
                                                        <GrEdit/>
                                                    </button>

                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No se encontraron idiomas</td>
                                    </tr>
                                )
                            )
                        })
                    }
                </div>

            </>
        )

    }
}
export default Idiomas