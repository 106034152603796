import React from 'react'
import { withRouter } from 'react-router-dom'
import control, { alertDanger, alertSuccess, form, local, modal, ocultarModal, setLocal } from '../../../core/core'
import RoundSpinner from '../../../components/roundSpinner/roundSpinner'
import vehiculos_modelos from '../../../components/storage/vehiculos/modelos'
import Table from '../../../components/table/table'
import headerSection from '../../../components/headerSection'

import { GrEdit } from "react-icons/gr";
import vehiculos_marca from '../../../components/storage/vehiculos/marcas'


class Modelos extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            modelos: [],
            marcas:[]
        }
    }


    async componentDidMount() {
        setLocal({ item: 'vehiculos_modelos', value: false })

        this.setState({ modelos: await vehiculos_modelos(),marcas: await vehiculos_marca()})


        this.setState({ loading: false })
    }

    async procesarNuevoModelo(e) {
        const nuevoModelo = await form({ api: 'vehiculos/marcasYmodelos', form: 'nuevoModelo' })
        if (nuevoModelo.respuesta) {
            await this.setState(prevState => ({
                ...prevState,
                modelos: [...prevState.modelos, nuevoModelo.respuesta]
            }))

            alertSuccess('Marca creada con éxito')

            this.setInLocal(this.state.modelos)


            ocultarModal()
        } else {
            alertDanger('Ha ocurrido un error al crear la marca')
        }
    }

    async procesarEditarModelo(e) {
        const editarModelo = await form({ api: 'vehiculos/marcasYmodelos', form: 'editarModelo' })
        if (editarModelo.respuesta) {
            let modelos = local('vehiculos_modelos')
            const index = modelos.findIndex(e => parseFloat(e.idModelo) === parseFloat(editarModelo.respuesta.idModelo))

            if (~index) modelos[index] = editarModelo.respuesta

            this.setState(prevState => ({
                ...prevState,
                modelos: modelos
            }))

            this.setInLocal(modelos)

            alertSuccess('Marca editada correctamente')

            ocultarModal()
        } else {
            alertDanger('Ha ocurrido un error al crear la marca')
        }
    }

    setInLocal(modelos){
        setLocal({item:'vehiculos_modelos',value:modelos})
    }


    async eliminarModelo(idModelo){
        const eliminar = await control({api:'vehiculos/marcasYmodelos',request:'eliminarModelo',data:idModelo})

        if(eliminar.respuesta){

            const modelos = this.state.modelos.filter(e => parseFloat(e.idModelo) !== parseFloat(idModelo))

            await this.setState(prevState => ({
                ...prevState,
                modelos: modelos
            }))


            this.setInLocal(this.state.modelos)

            ocultarModal()
        }else{
            alertDanger('No se pudo eliminar el modelo')
        }
    }

    procesarFormulario(e, tipoFormulario) {
        e.preventDefault()

        if (tipoFormulario === 'nuevoModelo') {
            this.procesarNuevoModelo()
        } else {
            this.procesarEditarModelo()
        }
    }


    confirmarEliminarModelo(d){


        return modal({
            header:'Eliminiar modelo',
            contenido: <>
                <h2>¿ Quieres eliminar {d.titulo} ?</h2>
                <button type="button" className="outlineDanger" onClick={() => this.eliminarModelo(d.idModelo)}>Si eliminar</button>
                <button type="button" className="success" onClick={() => ocultarModal()}>No eliminar</button>
            </>
        })
    }
    formularioModelo(d = {
        idModelo: 0,
        titulo: '',
        idMarca:0
    }) {
        const tipoFormulario = d.idModelo === 0 ? 'nuevoModelo' : 'editarModelo'
        return (
            <>
                <form id={tipoFormulario} onSubmit={e => this.procesarFormulario(e, tipoFormulario)}>

                    <div className="inputSection wrap">
                        <label>Nombre del modelo</label>
                        <input type="text" id="titulo" placeholder="Ingresa el nombre del modelo" defaultValue={d.titulo} required />
                    </div>

                    <div className="inputSection wrap">
                        <label>Marca</label>
                        <select id="idMarca" defaultValue={d.idMarca} required >
                            {
                                this.state.marcas.map(e => {
                                    return <option value={e.idMarca}>{e.titulo}</option>
                                })
                            }
                            </select>
                    </div>
                    <div className="inputSection">
                        <button type="submit" className="success">
                            Guardar
                        </button>
                        <button onClick={() => ocultarModal()} className="outlineDanger">
                            Cancelar
                        </button>
                        {
                            tipoFormulario === 'editarModelo' ? (
                                <>
                                    <input type="hidden" id="idModelo" value={d.idModelo} />
                                    <button onClick={() => this.confirmarEliminarModelo(d)}>
                                        Eliminar
                                    </button>
                                </>
                            ) : (
                                null
                            )
                        }
                    </div>
                </form>
            </>
        )
    }
    editarModelo(modelo){
        return modal(
            {
                header: 'Editar modelo',
                contenido: this.formularioModelo(modelo)
            }
        )
    }
    nuevoModelo() {
        return modal(
            {
                header: 'Nuevo modelo',
                contenido: this.formularioModelo({ idModelo: 0 })
            }
        )
    }
    render() {

        return (
            <>
                {
                    headerSection('Modelos')
                }

                <div className="appElement">
                    <button type="button" className="outlineSuccess" onClick={() => this.nuevoModelo()}>
                        + Nuevo modelo
                    </button>
                    {
                        Table({
                            id: 'modelos',
                            thead: [{
                                'title': 'Titulo'
                            }, {
                                'title': ' '
                            }
                            ],
                            tbody: this.state.loading ? (
                                <RoundSpinner />
                            ) : (
                                this.state.modelos.length > 0 ? (
                                    this.state.modelos.map(e => {
                                        return (
                                            <tr>

                                                <td>
                                                    {e.titulo}
                                                </td>
                                                <td>
                                                    <button type="button" className="outlineSuccess" onClick={() => this.editarModelo(e)}>
                                                        <GrEdit/>
                                                    </button>

                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No se encontraron modelos</td>
                                    </tr>
                                )
                            )
                        })
                    }
                </div>

            </>
        )

    }
}
export default withRouter(Modelos)