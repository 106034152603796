import { geA } from '../../core/core'
import './tableStyle.css'
import { AiOutlineFileExcel } from 'react-icons/ai'
import tableToExcel from '../exportTable/tableToExcel'
function Table(d) {
    return (
        <>
            <button type="button" className="outlineSuccess" onClick={() => tableToExcel({
                idTable: d.id,
                fileName: d.id,
            })}>
                <AiOutlineFileExcel />
            </button>


            <div className="tableContent" id={'containerTable' + d.idDiv}>
                <table id={d.id ?? ''} >

                    <thead>
                        <tr>
                            {
                                d.thead.map(e => {
                                    return (
                                        <th className={e.class ?? ''}>{e.title}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {d.tbody}
                    </tbody>
                </table>
            </div>

        </>
    )
}
export function togglePrintView(showPrintView) {
    if (showPrintView) {
        let elements = geA('.onlyPrint')

        elements.forEach(e => {
            e.classList.add('onlyPrintShow')
        })
    } else {
        let elements = geA('.onlyPrintShow')

        elements.forEach(e => {
            e.classList.remove('onlyPrintShow')
        })
    }
}
export default Table