import control, { local, setLocal } from "../../../core/core"

export default async function lista_proveedores(){
    let proveedores = local('lista_proveedores')

     if(!proveedores){
         proveedores = await control({ api: 'proveedores/proveedores', request: 'lista_proveedores' })
         proveedores = proveedores.respuesta ? proveedores.respuesta : []

         setLocal({item:'lista_proveedores',value:proveedores })

     }

    return proveedores 

}