import { Route } from 'react-router-dom'

import MiPerfil from '../views/mi-perfil/mi-perfil'
import Itinerarios from '../views/itinerarios/itinerarios'
import Itinerario from '../views/itinerario/itinerario'
import '../../styles/stylesTrabajador.css'
import { ge } from '../../core/core'
import Cuestionario from '../../publicSide/views/cuestionario/cuestionario'
import LiveMap from '../views/liveMap/LiveMap'
import Quizz from '../../publicSide/views/quizz/quizz'

export default function RouterTrabajador() {

    ge('#root').classList.add('rootTrabajador')

    return (
        <>


            <Route exact path="/itinerario/:idItinerario" >
                <Itinerario />
            </Route >

            <Route exact path={["/", "/itinerarios", "/dashboard"]} >
                <Itinerarios />
            </Route >
            <Route exact path="/mi-perfil" >
                <MiPerfil />
            </Route >

            <Route exact path="/quizz/:idItinerario" >
                <Quizz />
            </Route >
            <Route exact path="/quizz" >
                <Quizz />
            </Route >
            <Route exact path={["/cuestionario","/cuestionario/:dniParticipante"]} >
                <Cuestionario />
            </Route>

            

            <Route exact path="/live-map" >
                <LiveMap />
            </Route>
        </>
    )
}