import React from 'react'
import { GrEdit } from 'react-icons/gr'
import { Link } from 'react-router-dom'
import headerSection from '../../../components/headerSection'
import RoundSpinner from '../../../components/roundSpinner/roundSpinner'
import Table from '../../../components/table/table'
import control from '../../../core/core'



export default class Vehiculos extends React.Component {

    state = {
        loading: true,
        vehiculos: []
    }

    async componentDidMount() {
        this.listarVehiculos()
    }

    async listarVehiculos() {
        this.setState({ loading: true })
        const vehiculos = await control({ api: 'vehiculos/vehiculos', request: 'listarVehiculos' })
        if (vehiculos.respuesta) {
            this.setState({ vehiculos: vehiculos.respuesta })

        } else {
            this.setState({ vehiculos: [] })
        }

        this.setState({ loading: false })

    }
    render() {

        return (<>
            {
                headerSection('Vehículos')
            }
            <div className="appElement">
                <Link to="/vehiculo/0">
                    <button type="button" className="outlineSuccess btnSm">+ Nuevo</button>
                </Link>
                <Link to="/marcas">
                    <button type="button" className="outlineSuccess btnSm">Marcas</button>
                </Link>
                <Link to="/modelos">
                    <button type="button" className="outlineSuccess btnSm">Modelos</button>
                </Link>
            </div>
            {

            }
            <div className="appElement">

                {
                    Table({
                        id: 'guias',
                        thead: [{
                            'title': 'Nombre'
                        }, {
                            'title': 'Matrícula'
                        },
                        {
                            'title': 'Marca'
                        }, {
                            'title': 'Capacidad'
                        }, {
                            'title': ''
                        }
                        ],
                        tbody: this.state.loading ? (
                            <RoundSpinner />
                        ) : (
                            this.state.vehiculos.length > 0 ? (
                                this.state.vehiculos.map(e => {
                                    return (
                                        <tr>
                                            <td>{e.nombre}</td>
                                            <td>{e.matricula}</td>
                                            <td>{e.marca}</td>
                                            <td>{e.capacidad}</td>
                                            <td>
                                                <Link to={`/vehiculo/${e.idVehiculo}`}>
                                                    <button type="button" className="outlineSuccess">
                                                        <GrEdit />
                                                    </button>
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })

                            ) : (
                                <tr>
                                    <td>No se encontraron vehiculos</td>
                                </tr>
                            )
                        )
                    })
                }
            </div>

        </>

        )
    }
}